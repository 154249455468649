import React, { useState, useEffect } from "react";
import '../css/Video.css';

function Video(props) {
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (typeof window === "undefined") return; // Asegurar que estamos en el cliente

    const handleOrientation = (event) => {
      const maxTilt = 20; // Máxima inclinación permitida en grados
      const gamma = event.gamma || 0; // Izquierda/Derecha
      const beta = event.beta || 0; // Arriba/Abajo

      // Limitar valores
      const x = Math.max(Math.min(gamma, maxTilt), -maxTilt);
      const y = Math.max(Math.min(beta - 90, maxTilt), -maxTilt);

      // Escalar para desplazar
      const offsetX = (x / maxTilt) * 50; // Ajustar el rango de movimiento
      const offsetY = (y / maxTilt) * 50;

      // Actualizar el estado
      setOffset({ x: offsetX, y: offsetY });
    };

    // Agregar el evento si está soportado
    if (window.DeviceOrientationEvent) {
      window.addEventListener("deviceorientation", handleOrientation);
    } else {
      console.warn("DeviceOrientationEvent no está soportado en este navegador.");
    }

    // Limpiar el evento al desmontar
    return () => {
      window.removeEventListener("deviceorientation", handleOrientation);
    };
  }, []);

  useEffect(() => {
    if (typeof window === "undefined") return; // Evitar errores en SSR

    if (typeof DeviceOrientationEvent !== "undefined" && typeof DeviceOrientationEvent.requestPermission === "function") {
      DeviceOrientationEvent.requestPermission()
        .then((permissionState) => {
          if (permissionState === "granted") {
            console.log("Permisos otorgados para DeviceOrientationEvent");
          }
        })
        .catch(console.error);
    }
  }, []);

  useEffect(() => {
    if (typeof window === "undefined") return;

    const handleResize = () => {
      const video = document.getElementById("video1");
      if (video) {
        video.style.width = `${window.innerWidth}px`;
        video.style.height = `${window.innerHeight}px`;
      }
    };

    // Ajustar tamaño al cargar y al cambiar el tamaño
    handleResize();
    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    // Limpieza de eventos
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);

  //Al cambiar la orientación, verifica y ajusta las dimensiones del video dinámicamente
  /* useEffect(() => {
    const handleOrientationChange = () => {
      const video = document.getElementById("video1");
      if (video) {
        video.style.width = "auto";
        video.style.height = "auto";
        video.style.minWidth = "100%";
        video.style.minHeight = "100%";
      }
    };

    handleOrientationChange(); // Ajusta al cargar
    window.addEventListener("resize", handleOrientationChange);
    window.addEventListener("orientationchange", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []); */

 return (
<div className={props.caption}>
  <video id='video1' autoPlay loop={props.replay} muted style={{
          transform: `translate(calc(-50% + ${offset.x}px), calc(-50% + ${offset.y}px)) scale(1)`,
        }}>
    <source src={props.nombre} type = 'video/mp4' />
    Your browser does not support the video tag.
  </video>  


  {props.string}
{/*     <h1><Icon.VolumeMuteFill size={20} title=' ' ></Icon.VolumeMuteFill></h1> */}

</div>
 );
}


export default Video;