import React from "react";
import Video from '../components/video.js';
/* import Wsp from '../components/wsp.js'; */
import VideoGaleria from '../components/videoGall.js';

function GaleriaVideo() {

return (
  <>
  <Video  nombre="/assets/video/toytek.mp4" 
          caption="portada" 
          replay={true} 
   />
   <VideoGaleria></VideoGaleria></>
);
}

export default GaleriaVideo;