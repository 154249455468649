import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal"; 
import { Link, useParams, useNavigate } from 'react-router-dom';
import BreadcrumbVideo from './breadcrumbVideo.js';
import {XCircleFill, CashCoin} from 'react-bootstrap-icons';

const YouTubeGallery = () => {
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [videoShow, setVideoShow] = useState([]);
  const url = useParams().url;
  const navigate = useNavigate();

  const temporadasVideos = [
    { id: "1", titulo: "HE-MAN - LOS AMOS DEL UNIVERSO", temporada: "TEMP1  1 -13", url: "he-man-los-amos-del-universo-filmation-temporada-1-parte-1", urlIntro: "he-man-y-los-amos-del-universo-filmation"},
    { id: "2", titulo: "HE-MAN - LOS AMOS DEL UNIVERSO", temporada: "TEMP1 14-26", url: "he-man-los-amos-del-universo-filmation-temporada-1-parte-2", urlIntro: "he-man-y-los-amos-del-universo-filmation"},
    { id: "3", titulo: "HE-MAN - LOS AMOS DEL UNIVERSO", temporada: "TEMP1 27-39", url: "he-man-los-amos-del-universo-filmation-temporada-1-parte-3", urlIntro: "he-man-y-los-amos-del-universo-filmation"},
    { id: "4", titulo: "HE-MAN - LOS AMOS DEL UNIVERSO", temporada: "TEMP1 40-52", url: "he-man-los-amos-del-universo-filmation-temporada-1-parte-4", urlIntro: "he-man-y-los-amos-del-universo-filmation"},
    { id: "5", titulo: "HE-MAN - LOS AMOS DEL UNIVERSO", temporada: "TEMP1 53-65", url: "he-man-los-amos-del-universo-filmation-temporada-1-parte-5", urlIntro: "he-man-y-los-amos-del-universo-filmation"},
    { id: "6", titulo: "HE-MAN - LOS AMOS DEL UNIVERSO", temporada: "TEMP2 66-78", url: "he-man-los-amos-del-universo-filmation-temporada-2-parte-1", urlIntro: "he-man-y-los-amos-del-universo-filmation"},
    { id: "7", titulo: "HE-MAN - LOS AMOS DEL UNIVERSO", temporada: "TEMP2 79-91", url: "he-man-los-amos-del-universo-filmation-temporada-2-parte-2", urlIntro: "he-man-y-los-amos-del-universo-filmation"},
    { id: "8", titulo: "HE-MAN - LOS AMOS DEL UNIVERSO", temporada: "TEMP2 92-104", url: "he-man-los-amos-del-universo-filmation-temporada-2-parte-3", urlIntro: "he-man-y-los-amos-del-universo-filmation"},
    { id: "9", titulo: "HE-MAN - LOS AMOS DEL UNIVERSO", temporada: "TEMP2 105-117", url: "he-man-los-amos-del-universo-filmation-temporada-2-parte-4", urlIntro: "he-man-y-los-amos-del-universo-filmation"},
    { id: "10", titulo: "HE-MAN - LOS AMOS DEL UNIVERSO", temporada: "TEMP2 118-130", url: "he-man-los-amos-del-universo-filmation-temporada-2-parte-5", urlIntro: "he-man-y-los-amos-del-universo-filmation"},
    { id: "11", titulo: "EL SHOW DE LA PANTERA ROSA 1964", temporada: "TEMP1  1- 2", url: "el-show-de-la-pantera-rosa-temporada-1", urlIntro: "el-show-de-la-pantera-rosa"},
    { id: "12", titulo: "EL SHOW DE LA PANTERA ROSA 1965", temporada: "TEMP2  3-14", url: "el-show-de-la-pantera-rosa-temporada-2", urlIntro: "el-show-de-la-pantera-rosa"},
    { id: "13", titulo: "EL SHOW DE LA PANTERA ROSA 1966", temporada: "TEMP3 15-24", url: "el-show-de-la-pantera-rosa-temporada-3", urlIntro: "el-show-de-la-pantera-rosa"},
    { id: "14", titulo: "G.I. JOE: UN VERDADERO HÉROE AMERICANO", temporada: "TEMP1   1-13", url: "gi-joe-un-verdadero-heroe-americano-temporada-1", urlIntro: "gi-joe-un-verdadero-heroe-americano"},
    { id: "15", titulo: "G.I. JOE: UN VERDADERO HÉROE AMERICANO", temporada: "TEMP2  56-69", url: "gi-joe-un-verdadero-heroe-americano-temporada-2", urlIntro: "gi-joe-un-verdadero-heroe-americano"},
    { id: "31", titulo: "CAPITAN CENTELLA", temporada: "EP  1-13", url: "capitan-centella-capitulos-parte-1", urlIntro: "capitan-centella"},
    { id: "32", titulo: "CAPITAN CENTELLA", temporada: "EP 14-26", url: "capitan-centella-capitulos-parte-2", urlIntro: "capitan-centella"},
    { id: "33", titulo: "CAPITAN CENTELLA", temporada: "EP 27-32", url: "capitan-centella-capitulos-parte-3", urlIntro: "capitan-centella"},];

  const API_KEY = process.env.REACT_APP_VIDEO_GALLERY; // Reemplaza con tu clave de API
  const videoIds_MOTU1 = [  
    "_lxmsKSGGk4", "B4GbY52lWI0", "hfhpqxOcCns", "DMxyeTw_f3U", "FOWKcuupA80", "YiMMAwGUzjM", "g1m_TGjjr8o", "whwFpibs6_Q", "72r-xU9Y4Nw", "HA29SfvwgXs", // Video 10
    "lmjeUysq_BU", "F_UXeS4fxf8", "nMRB-LH_cUY", // 13
  ];//65 x 2 Temporadas
  const videoIds_MOTU2 = [  
    "jiLZh5G8Fi0", "aVARR0C03N8", "B5eP41c04hc", "7pF0pp_ciww", "1GBS3Ym0Wzk", "04cWo6VE7JM", "ssnre7JH8zY", // video 20
    "VJZ5G67wLuc", "zRC06Fg_pSg", "GMtvBh6wb6w", "lFL2sXLYCJc", "nfzBuh_KtRI", "3Q34P39LoPE", 
  ];//65 x 2 Temporadas
  const videoIds_MOTU3 = [  
    "uJJ2JmRBIDk", "y3c2pidtfMk", "SWPO4QTbGes", "cTVADKL6V7Q", // video 30
    "g-hyBU_0mNA", "YWiiFKkqDCY", "LwSlZm0UTXo", "iQNPvAKMEwU", "piUh1dkkpTs", "-P1lOS8K6Ik", "KHyHxe6kiRM", "kWqG5GeQi9g", "z2UH0TiClIM", 
  ];//65 x 2 Temporadas
  const videoIds_MOTU4 = [  
    "CnC3MRJCnq8", // video 40
    "CIUnVWy3ViE", "QmSZsVYIzpQ", "IZN5BKdBOKI", "GF7afIg9sVQ", "eqTsjtFRFxA", "rxBsPlSm9Hs", "Y-2t7EwpshA", "P8iy1eV0jeE", "SNy6onPDMxg", "IKwuFXacgcI", // video 50
    "sEHIgAoU0hQ", "qUkZJRfj1ys", 
  ];//65 x 2 Temporadas

  const videoIds_MOTU5 = [  
    "zBVpbeFhIJk", "cntG0IyxID8", "TglSyb4EMkM", "hsZr6pDqWWY", "9l4mzScYgy4", "bVOBvTf16cM", "COtq_Zq6IOc", "rGsRmyjpf6s", // video 60
    "noR0HU-r5XI", "PV-2ptnz-ls", "8FWUZbTssVo", "5bqIUAXb7LE", "RCRopt3kbxE",  // video 65
  ];//65 x 2 Temporadas
 
  const videoIds_MOTU6 = [  
    "lUa21ktGRBk", "auqq9-J042g", "t-YVSKreoMw", "lXHIjwXdKJ4", "ainV3WJVHTM", "icQ_jXVG-WE", "1H7PgeLq4e0", "el0rRXh5UNg", "GSytesz2q6c", "nYoSjCKQe1Q", // video 66-75
    "Mbj4kWp9ayE", "3p8R9FIeM_4", "OulyXuDJkAs", //78
  ];//65 x 2 Temporadas

  const videoIds_MOTU7 = [  
     "ZPXe8fGs92g", "GiiPfcQaKlc", "OJfOirCj4gI", "-PVaLoAXM9Q", "jL3Hz-xry8I", "NTsCKovBWOQ", "M5y1acPJsss", // video 85
    "5QpSGY694Pk", "BNZK9aTd4fE", "i2WY0y13rLU", "IJpJfzNxeAc", "y_MLTg4ZGLQ", "Pe9SSleHHgY",//91
  ];//65 x 2 Temporadas
  const videoIds_MOTU8 = [  
    "kK6Fi95GhcM", "Ba1D71ZfXFg", "hY8qUfY3YDk", "S1p4SS3FLQg", "k2zR-tGW8lM", //106
    "Vf1h9ZpBfpE", "48BybVAVRp8", "EI1P14WhjDI", "Kb_n2pNiPz4", "TutibQPHc0E", "yMfIBRYWcnY", "hVIOOQRXHIM", "VwFZbGb4U-E", //104
  ];//65 x 2 Temporadas
  const videoIds_MOTU9 = [  
    "NMwBRXCP2PA", "l-Kj3CmTMyw", "L9vASmNhqlY", "teqWRIEOe-c", "kxfRDbtVV3Y", //109
    "CR8orQFWinE", "9riS4g9OF04", "DSsq4Xvkm4o", "3Fb8XN6h8KY", "yw4CyMtBPL4", "MfZhmvzKIN0", "FOSgAAYzI5M", "SEMMDLGeNrc", //117
  ];//65 x 2 Temporadas
  const videoIds_MOTU10 = [  
    "xO2MW_PRiZI", "ITfmNUm1vH8", "kaQY3R0sfOg", "SIqamhxe8c8", "JjKfZnIwRE0", "NTAMLyK_jB0", "_6veNZuIBhA", "gpiBpsWrSi8", //125
    "yWS3A4MQqWw", "VUciMmwUp2E", "493Br8Lfd74", "mIszhV04Tq0", "ZqJDjk6UGQo",  // video 130
  ];//65 x 2 Temporadas

  const videoId_Pantera_Rosa1 = [  //124 x 15 Temporadas
    "30MWO8kVOYc", "Z0nk50iX2Bc", // Video 2
  ];

  const videoId_Pantera_Rosa2 = [  //124 x 15 Temporadas
    "yS5t7aQAL6w", "Uo-CdldsjmU", "VUc6D-Ge_lo", "_CyqvKhZ94s", "AMm1kULUFG4", "Bh2beA64kqc", "j-9_3N3oJV8", "6fxxKupEKYc", // Video 12
    "wZGEijL3Nho","HcLpVPq1_HU","yJDogNXeVOU","fn6C_BL_xVo"
  ];

  const videoId_Pantera_Rosa3 = [  //124 x 15 Temporadas
    "I7jyeOozeyE", "W7SP18Kgtr0", "MLTxfrF0yZ4", "jXpCb8rBEOk", "1Mbgz5QTQLY", "YxnSPcpAifQ", "VPfcyAjwXsg", "p8M7JfYi9oA", // Video 10
    "5YppoYaPBIk","aNm9WFWXVac",
  ];

  const videoId_gijoe1 = [  //95 x 2 Temporadas 55 y 30
    "khnZQR3eByI", "MuaxfIJQAMU", "7vy4FsEXSIA", "hy7dZC3PcmE", "YwPdmtNS-Sw", "f0LjkI2IGmM", "hawnZcgtqsU", "h0y4TXEm98w", // Video 8
    "gcn3UcAW-eY","fqv7SyTteSQ","pnVRDxbu9jQ","E85kpXnpbJc","vTVg0hU43bI", //13
  ];

  const videoId_gijoe2 = [  //95 x 2 Temporadas 55 y 30
    "", "", "", "", "", "", "", "", // Video 8
    "","","","","", //26
  ];

  const videoId_gijoe3 = [  //95 x 2 Temporadas 55 y 30
    "", "", "", "", "", "", "", "", // Video 8
    "","","","","", //39
  ];

  const videoId_gijoe4 = [  //95 x 2 Temporadas 55 y 30
    "", "", "", "", "", "", "", "", // Video 8
    "","","","","", //52
  ];

  const videoId_gijoe5 = [  //95 x 2 Temporadas 55 y 30
    "", "", "" //55
  ];

  const videoId_gijoe6 = [  //95 x 2 Temporadas 55 y 30
    "", "", "", "", "", "", "", "", // Video 8
    "","","","","", //13
  ];

  const videoId_gijoe7 = [  //95 x 2 Temporadas 55 y 30
    "", "", "", "", "", "", "", "", // Video 8
    "","","","","", //26
  ];

  const videoId_gijoe8 = [  //95 x 2 Temporadas 55 y 30
    "", "", "" ,"" //30
  ];

  const videoId_centella1 = [  //33 x 1 Temporadas 55 y 30
    "2t3zM1YYgIU", "9LkLGMDrEQk", "nQRA9SRfw8c", "O6XBX95s5gU", "JRGBExL3EYQ", "ShN3jhv4swI", "6opo2Z6r7oM", "hKwh6P74Rkg", // Video 8
    "bNsj5Jb1S-Y","ZFzTcvfX3EM","NkmBhRWA5VI","gmteLmc_LIY","RY5taPEgSdw", //13
  ];


  const videoId_centella2 = [  //33 x 2 Temporadas 55 y 30
    "tnOYnyFfNls", "E6N8V8poA9U", "PuTWN0I6pmI", "rAVbonmhcYo", "CUnESDb4Elg", "2tX1Pv2HEDs", "eXdwnJ79Uw0", "YwLoZjHS12c", // Video 8
    "e0VwYZX5OgI","aRqmos8HnV4","emu78ZGspeY","gv0qJ_kgtx0","UqT3LfEJqFk", //26
  ];

  const videoId_centella3 = [  //32 x 2 Temporadas 55 y 30
    "jfq55LxRDag", "4yzdRdctrec", "RjTJp_FJlRg", "1ctpv0yZcS0", "b_ZS6rtolG4", "misYqQpp9FM", //32
  ];


  useEffect(() => {
    // Filtrar la temporada según la URL
    const temporada = temporadasVideos.find((t) => t.url === url);

    if (!temporada) {
      // Si no se encuentra, redirigir al inicio
      navigate("/home");
      return;
    }

    // Seleccionar los videos según la temporada
    if (temporada.id === "1") {
      setVideoShow(videoIds_MOTU1);
    } else if (temporada.id === "2") {
      setVideoShow(videoIds_MOTU2);
    }else if (temporada.id === "3") {
      setVideoShow(videoIds_MOTU3);
    }else if (temporada.id === "4") {
      setVideoShow(videoIds_MOTU4);
    }else if (temporada.id === "5") {
      setVideoShow(videoIds_MOTU5);
    }else if (temporada.id === "6") {
      setVideoShow(videoIds_MOTU6);
    }else if (temporada.id === "7") {
      setVideoShow(videoIds_MOTU7);
    }else if (temporada.id === "8") {
      setVideoShow(videoIds_MOTU8);
    }else if (temporada.id === "9") {
      setVideoShow(videoIds_MOTU9);
    }else if (temporada.id === "10") {
      setVideoShow(videoIds_MOTU10);
    } else if (temporada.id === "11") {
      setVideoShow(videoId_Pantera_Rosa1);
    }else if (temporada.id === "12") {
      setVideoShow(videoId_Pantera_Rosa2);
    }else if (temporada.id === "13") {
      setVideoShow(videoId_Pantera_Rosa3);
    }else if (temporada.id === "14"){
      setVideoShow(videoId_gijoe1);/*
    }else if (temporada.id === "15")  {
      setVideoShow(videoId_gijoe2);
    }else if (temporada.id === "16") {
      setVideoShow(videoId_gijoe3);
    }else if (temporada.id === "17") {
      setVideoShow(videoId_gijoe4);
    }else if (temporada.id === "18") {
      setVideoShow(videoId_gijoe5);
    }else if (temporada.id === "19") {
      setVideoShow(videoId_gijoe6);
    }else if (temporada.id === "20") {
      setVideoShow(videoId_gijoe7);
    }else if (temporada.id === "21")  {
    setVideoShow(videoId_gijoe8); */
    }else if (temporada.id === "31") {
      setVideoShow(videoId_centella1);
    }else if (temporada.id === "32") {
      setVideoShow(videoId_centella2);
    }else if (temporada.id === "33") {
      setVideoShow(videoId_centella3);
    } else {
      setVideoShow([]); // Sin videos
    }
  }, [url, navigate]);

  useEffect(() => {
    // Cargar los datos de los videos de YouTube
    const fetchVideoData = async () => {
      try {
        if (videoShow.length > 0) {
          const response = await axios.get(`https://www.googleapis.com/youtube/v3/videos`, {
            params: {
              id: videoShow.join(","),
              part: "snippet,contentDetails",
              key: API_KEY,
            },
          });
          console.log("API Response:", response.data);
          setVideos(response.data.items || []);
        } else {
          console.log("No video IDs available for fetching.");
          setVideos([]); // Si no hay videos, vaciar la lista
        }
      } catch (error) {
        console.error("Error fetching video data:", error.response?.data || error.message);
        alert(`Error fetching video data: ${error.response?.data?.error?.message || error.message}`);
      }
    };

    fetchVideoData();
  }, [videoShow, API_KEY]);
  
  const openModal = (video) => {
    setSelectedVideo(video);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedVideo(null);
  };
  const temporada2 = temporadasVideos.find((t) => t.url === url);
  return (<div style={{paddingTop: '10px'}}>
    <BreadcrumbVideo categoria={temporada2.titulo} categoria1={'Cartoons'} descripcion={temporada2.titulo + ' ' + temporada2.temporada} url={'/galeria-de-videos/cartoons/' + temporada2.urlIntro} url1={'/galeria-de-videos/cartoons'}></BreadcrumbVideo>
     <div className="youtube-gallery" style={{ position: 'relative'}} >
            <h2 style={{color: 'white', paddingLeft: '4rem'}}>Galería de Videos</h2>
            <Link to="https://link.mercadopago.cl/toytek" className="text-decoration-none" target="_blank" style={{ paddingLeft: '4rem', color: 'yellow'}}><CashCoin color='yellow' size={16}></CashCoin>&nbsp;&nbsp;Donate</Link>
      <div
        className="video-grid"
        style={{
          position: 'relative',
          display: "flex",
          flexWrap:  "wrap",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
          gap: "20px",
          fontSize: '0.8rem', paddingTop: "20px"
        }}
      >
        {videos.map((video) => (
          <div
            key={video.id}
            onClick={() => openModal(video)}
            style={{ cursor: "pointer", textAlign: "center", paddingTop: '5px' }}
          >
            <img
              src={video.snippet.thumbnails.medium.url}
              alt={video.snippet.title}
              style={{ width: "315px", borderRadius: "8px" }}
            />
            <p style={{backgroundColor:'#fff', color:'#000'}}>
  {video.snippet.title.length > 50
    ? video.snippet.title.substring(0, 47) + "..."
    : video.snippet.title}</p>
          </div>
        ))}
      </div>

      {selectedVideo && (
        <Modal
  isOpen={modalIsOpen}
  onRequestClose={closeModal}
  style={{
    content: {
      maxWidth: "800px",
      margin: "auto",
      padding: "20px",
      borderRadius: "15px",
      border: "none",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
      backgroundColor: "#f9f9f9",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.6)",
    },
  }}
>
  <div style={{ position: "relative" }}>
    {/* Close Button */}
    <button
      onClick={closeModal}
      style={{
        position: "absolute",
        top: "10px",
        right: "10px",
        background: "transparent",
        border: "none",
        cursor: "pointer",
        fontSize: "1.5rem",
        color: "#333",
      }}
      aria-label="Close"
    >
      <XCircleFill />
    </button>

    {/* Video Content */}
    <h2
      style={{
        fontSize: "1.5rem",
        color: "#333",
        marginBottom: "10px",
        textAlign: "center",
        paddingTop: '60px',
      }}
    >
      {selectedVideo.snippet.title}
    </h2>
    <p
      style={{
        fontSize: "1rem",
        color: "#666",
        marginBottom: "5px",
        textAlign: "center",
      }}
    >
      Canal:{" "}
      <span style={{ fontWeight: "bold", color: "#007BFF" }}>
        {selectedVideo.snippet.channelTitle}
      </span>
    </p>
    <p
      style={{
        fontSize: "1rem",
        color: "#666",
        marginBottom: "20px",
        textAlign: "center",
      }}
    >
      Publicado el:{" "}
      <span style={{ fontWeight: "bold" }}>
        {new Date(selectedVideo.snippet.publishedAt).toLocaleDateString()}
      </span>
    </p>
    <div style={{ textAlign: "center" }}>
      <iframe
        width="100%"
        height="400"
        src={`https://www.youtube.com/embed/${selectedVideo.id}`}
        frameBorder="0"
        allow="autoplay; encrypted-media"
        allowFullScreen
        style={{
          borderRadius: "10px",
          border: "1px solid #ccc",
        }}
      ></iframe>
    </div>
  </div>
</Modal>
      )}
    </div></div>
  );
};

export default YouTubeGallery;