import React, { useEffect, useState } from 'react';
import "../css/productos.css";
import dataProductos from "../assets/productos.json";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import { Cart4, } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loader from './preloader.js'
function App(props) {
  const navigate = useNavigate();
  const [productos, setProductos] = useState([]);
  const [productosShow, setProductosShow] = useState([]);
  const productosEnCarrito = JSON.parse(localStorage.getItem("toytek-carrito")) || [];
  const [loading, setLoading] = useState(true);
  const nf = new Intl.NumberFormat("es-CL");
  const [Catproducto, setCatProducto] = useState(props.categoriaId);

  function agregarAlCarrito(e) {
    const idBoton = e.currentTarget.id;
    const productoAgregado = productos.find((producto) => producto.id === String(idBoton));
    /*    */

    if (productosEnCarrito.some((producto) => producto.id === String(idBoton))) {
      /* const index = productosEnCarrito.findIndex((producto) =>  producto.id ===  Number(idBoton));
      productosEnCarrito[index].cantidad++; */
    }
    else {

      productoAgregado.cantidad = 1;
      productosEnCarrito.push(productoAgregado);
      localStorage.setItem("toytek-carrito", JSON.stringify(productosEnCarrito));
      window.dispatchEvent(new Event("storage"));
      const totalItems = productosEnCarrito.reduce((total, item) => total + item.cantidad, 0);
      let cartSummaryHtml = `
    <div style="text-align: center; font-size: 16px; color: #333;">
      <div style="display: flex; align-items: center; justify-content: center; gap: 10px; margin-bottom: 20px;">
        <span style="font-size: 20px; font-weight: bold;">${totalItems} Producto(s)</span><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" style="color:#04890c;" fill="currentColor" class="bi bi-cart-check-fill" viewBox="0 0 16 16">
  <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0m7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708"></path>
</svg></div>
    </div>
  `;
      Swal.fire({
        position: "center",
        icon: "success",
        iconColor: "#04890c",
        title: "¿ir a pagar?",
        html: cartSummaryHtml,
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Si",
        showCancelButton: true,
        cancelButtonColor: "red",
        cancelButtonText: "No, gracias."
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/cart');
        }
      });
    }

  }

  useEffect(() => {
    // Definir la función asíncrona dentro del useEffect
    const fetchProductos = async () => {
      try {
        const storedProductos = sessionStorage.getItem('productos');
        if (storedProductos) {
          setProductos(JSON.parse(storedProductos));
          return JSON.parse(storedProductos);
        } else {
          // Realizar la solicitud GET a la API
          const response = await axios.get('https://mp.toytek.cl/scrud/obtener-productos');
          if (response.status === 200 && response.data) {
            setProductos(response.data); // Actualizar el estado con los datos obtenidos
            sessionStorage.setItem('productos', JSON.stringify(response.data));
            return response.data;
          }
        }
      } catch (error) {
        setProductos(dataProductos);
        console.error('Error al obtener las Productos:', error);
      } finally {
        setLoading(false);
      }
    };
    setLoading(true)
    fetchProductos(); // Llamar a la función asíncrona
    setCatProducto(props.categoriaId);

  }, [props.categoriaId]); // Array de dependencias vacío para ejecutar el efecto solo una vez

  useEffect(() => {

    if (Catproducto === String(0)) {
      setProductosShow(productos.filter((producto, i) => producto.vendido === 'NO' && producto.cantidad > 0).sort(() => 0.5 - Math.random()));
    } else if (Catproducto === String(15) || Catproducto === String(16)) {
      setProductosShow(productos.filter((producto, i) => producto.vendido === 'NO' && producto.cantidad > 0 && producto.categorias === Number(Catproducto)).sort(() => 0.5 - Math.random()));
    } else if (Catproducto === String(13)) {
      setProductosShow(productos.filter((producto, i) => producto.destacado === 'SI' && producto.vendido === 'NO' && producto.cantidad > 0).sort(() => 0.5 - Math.random()).slice(0, 8));
    } else if (Catproducto === String(14)) {
      setProductosShow(productos.filter((producto, i) => producto.novedad === 'SI').sort(() => 0.5 - Math.random()).slice(0, 8));
    } else {
      setProductosShow(productos.filter((producto, i) => producto.categorias === Number(Catproducto)).sort(() => 0.5 - Math.random()));
    }
  }, [productos, Catproducto]);

  const loaderOverlayStyle = {
    position: "relative",
    top: 0,
    left: 0,
    width: "90%",
    height: "100vh",
    backgroundColor: "#f8f9fa", // Fondo semitransparente
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000, // Asegura que el loader esté por encima de todo
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
  };

  if (loading) {
    // Muestra un spinner o indicador de carga mientras se verifica la autenticación
    return (
      <div className={`container-fluid ${props.pTop} pb-3`} style={loaderOverlayStyle}>
        <Loader className="justify-content-center" />
      </div>
    );
  }



  return (<div className={`container-fluid ${props.pTop} pb-3`} >
    <h2 className="section-title position-relative text-uppercase mx-xl-5 mb-4" key={`${Catproducto}`}><span className="bg-secondary pr-3">{props.categoriaNombre}</span></h2>
    <div className="row px-xl-5">
      {productosShow.length > 0 &&
        productosShow.map((producto, i) => {
          let valorProductoArt = `$ ${nf.format(producto.valor)}`;
          let fondo_item = "bg-light";
          let estadoV = "visible";
          let oferta = 'none';
          let product_valor = "product-valor";
          let product_name_vendido = "product-name";
          if (producto.valor < producto.valor_anterior)
            oferta = 'visible';
          if (producto.vendido !== "NO") {
            valorProductoArt = "VENDIDO";
            fondo_item = "bg-light-vendido";
            estadoV = "hidden";
            product_valor = "product-valor-vendido";
            product_name_vendido = "product-name-vendido";
          }
          else {
            fondo_item = "bg-light";
          }

          let imagen;

          if (!producto.foto1.includes('_i.JPG') && !producto.foto1.includes('png')) {
            imagen = `${producto.foto1}_i.JPG`;
          } else {
            imagen = `${producto.foto1}`;
          }


          return (

            <div className="col-lg-3 col-md-4 col-sm-6 pb-1" key={i}>
              <div className={`product-item ${fondo_item} mb-4`}>
                <Link to={`/products/${producto.url}`} title={producto.descripcion} aria-label={producto.descripcion}>
                  <div className="product-LazyLoadImage position-relative overflow-hidden">

                    <LazyLoadImage className="LazyLoadImage-fluid w-100 img-fluid" src={imagen} title={producto.descripcion} aria-label={producto.descripcion} width="500px" height="333px" />
                    <div className="product-action">
                      {/*  <button className="btn btn-outline-primary2 producto-agregar" style={{ visibility: estadoV }} id={`${producto.id}`} onClick={agregarAlCarrito} title="Abrir carrito de compras" aria-label="Abrir carrito de compras"><Cart4 size={24} color='white'></Cart4></button> */}
                    </div>
                  </div>
                </Link>
                <div className="text-center py-4">
                  <Link className="text-decoration-none text-truncate" to={`/products/${producto.url}`} style={{ color: 'black, !important', fontDisplay: 'swap' }}><p className={product_name_vendido}>{producto.descripcion}</p></Link>
                  <div className="d-flex align-items-center justify-content-center mt-2">
                    <p className={product_valor}>{valorProductoArt}</p><p className="text-muted-productos ml-2"><del style={{ color: 'brown', display: oferta, fontWeight: 'lighter' }}>${nf.format(producto.valor_anterior)}</del></p>
                  </div>
                  <div className="d-flex align-items-center justify-content-center mb-1">
                    {/*<Link className="btn btn-outline-primary2" to={`/products/${producto.url}`}><InfoLg size={24} color='blue'></InfoLg></Link>*/}
                    {/*  </div>
                  <div className="d-flex align-items-center justify-content-center mb-1"> */}
                    &nbsp;<button className="btn btn-outline-primary2 producto-agregar" style={{ visibility: estadoV }} id={`${producto.id}`} onClick={agregarAlCarrito} title="Abrir carrito de compras" aria-label="Abrir carrito de compras"><Cart4 size={24}></Cart4></button>
                  </div>
                </div>
              </div>
            </div>
          )
        })
      }
    </div>
  </div>)
}

export default App;
