import React from 'react';
import '../css/Preloader.css'; // Estilos para el preloader

const Preloader = (props) => {
  return (
    <div
className={`${props.className} align-items-center flex-column`}>
<img
  src="/assets/logo/toytek.png" // Reemplaza con la URL de tu logotipo
  alt="Logo" className="img-fluid" width="150px" height="29px"
/>
<div className="spinner-border text-primary" role="status">
  <span className="visually-hidden">Cargando...</span>
</div>
<p className="mt-3 text-primary">Cargando, por favor espera...</p>
</div>
  );
};

export default Preloader;