import React from "react";
import Video from '../components/video.js';
import Contacto from '../components/contacto.js';
import Breadcrumb from '../components/breadcrumb.js';

function App() {

return (
  <>
  <Video  nombre="/assets/video/toytek.mp4" 
          caption="portada" 
          replay={true} 
   /><div><Breadcrumb categoria={`Contacto`} descripcion={``} url={``}></Breadcrumb></div>
   <div><Contacto/></div></>
);
}

export default App;