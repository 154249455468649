import React from "react";
import Video from '../components/video.js';
import Categorias from '../components/categorias.js';
import Productos from '../components/Productos.js';
function App() {

  return (
    <>
      <Video nombre="../assets/video/toytek.mp4"
        caption="portada"
        replay={true}
      />
      <div>
        <Productos categoriaId="13" categoriaNombre="Productos Destacados" />
      </div>
      <div>
        <Categorias></Categorias>
      </div>
      <div>
        <Productos categoriaId="14" categoriaNombre="Productos Recientes" />
      </div></>
  );
}

export default App;