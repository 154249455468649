import React from "react";
import {Link, CameraReels} from 'react-bootstrap-icons';
import Video from '../components/video.js';
/* import Wsp from '../components/wsp.js'; */
import Categorias from '../components/categorias.js';
import Carousel from '../components/carousel.js';
import Productos from '../components/Productos.js';
import Galeria from '../components/galeria_recuadros.js';
function App() {

return (
  <>
  <Video  nombre="/assets/video/toytek.mp4" 
          caption="portada" 
          replay={true} 
    />
            <Carousel numero1='1'  
            carouseltitulo1=''
            carouseltexto1=''
            carouselcarpeta1='carousel'
            carouselboton1={<Link size={25}></Link>}
            visiblebtn1='hidden'
            
            numero2='2'  
            carouseltitulo2=''
            carouseltexto2=''
            carouselcarpeta2='carousel'
            carouselboton2={<Link size={25}></Link>}
            visiblebtn2='hidden'
            
            numero3='3'  
            carouseltitulo3='Servicios Web a la medida.'
            carouseltexto3='Te ayudamos con tu sitio web, también desarrollos a la medida.'
            carouselcarpeta3='carousel'
            carouselboton3={<Link size={25}></Link>}
            visiblebtn3='visible'
            
            numeroa1='3'
            tituloa1='Videos Gratis'
            textoa1=''
            carpetaa1='productos'
            botona1={<CameraReels size={25}></CameraReels>}
            visiblebotona1='visible'
            numerob2='2'
            titulob2='Valparaíso - Chile 🇨🇱'
            textob2='Despachos'
            carpetab2='productos'
            botonb2={<Link size={25}></Link>}
            visiblebotonb2='visible'
            >
              </Carousel>
              <Productos categoriaId="13" categoriaNombre="Productos Destacados" />
              <Galeria />
              <Categorias></Categorias>
              <Productos categoriaId="14" categoriaNombre="Productos Recientes" /></>
);
}

export default App;