import React, { useState, useRef } from 'react';
import { QRCodeCanvas } from 'qrcode.react';

function QRCodeGenerator({url, show, ssizze}) {
  const [link, setLink] = useState(url);
  return (
    <div style={{paddingTop: '5px'}}>
      {link && (
        <div>
            <div >
                <QRCodeCanvas value={link} size={ssizze} level="H" includeMargin={true} />
            </div>
                <p style={{backgroundColor: '#fff', color: '#000'}}>* Escanea este código QR para abrir el enlace del producto.</p>
            </div>
      )}
    </div>
  );
}

export default QRCodeGenerator;
