import React, { useEffect } from 'react';
import Video from '../components/video.js';
/* import Wsp from '../components/wsp.js'; */
import Unsubs from '../components/Unsubscribe.js';
import CryptoJS from "crypto-js";
import { useNavigate, useLocation } from 'react-router-dom';

function Unsubscribe() {
  const navigate = useNavigate();
  const location = useLocation(); // Captura la ubicación actual
  const queryParams = new URLSearchParams(location.search); // Extrae los parámetros de búsqueda
  const encryptedUnsubscribe = queryParams.get("unsubscribe"); // Obtén el valor del parámetro "account"
  const unsubscribe = encryptedUnsubscribe?.replace(/ /g, '+');

  // Desencriptar
  const decrypt = (cipherText) => {
    const secretKey = process.env.REACT_APP_CRYPTOJ_KEY;
    try {
      // Restaurar formato de Base64
      const restoredCipherText = cipherText.replace(/-/g, '+').replace(/_/g, '/');

      // Desencriptar
      const bytes = CryptoJS.AES.decrypt(restoredCipherText, secretKey);
      return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error('Error al desencriptar:', error);
      return null; // Devuelve null si el descifrado falla
    }
  };

  useEffect(() => {
    if (!unsubscribe) {
      /* const urlShow = decrypt(decodeURIComponent(unsubscribe)); */
      /* console.log('Valor descifrado:', urlShow); */
/*     } else { */
     /*  const urlHome = '/';
      let win = window.open(urlHome, '_parent');
      win.focus(); */
      // Redirige al usuario al home sin recargar la página
      navigate('/');
      /* console.log('No se proporcionó el parámetro "unsubscribe"'); */
    }
  }, [unsubscribe, navigate]);

  return (
    <>
      <Video nombre="/assets/video/toytek.mp4"
        caption="portada"
        replay={true}
      /><Unsubs usuario={decrypt(decodeURIComponent(unsubscribe))}/>
      {/* <Wsp /> */}</>
  );
}

export default Unsubscribe;