import React from 'react';
import '../css/policy.css';
/* import { Link } from 'react-router-dom'; */

function Header() {
    return (<div className="container" style={{ opacity: 0.9, padding: "20px" }}>
        <section className="policy-section bg-blue">
          {/* Pagos */}
          <div className="policy-item">
            <h2>Pagos</h2>
            <p className="lead">
              <strong>TRANSFERENCIAS</strong> (por WhatsApp) a Cuenta Rut, Mercadopago y Paypal.
            </p>
            <ul>
              <li>Aceptamos la mayoría de los medios de pago.</li>
              <li>Los valores incluyen impuestos.</li>
            </ul>
          </div>
          {/* Despachos */}
          <div className="policy-item">
            <h2>Despachos</h2>
            <p className="lead">
              Los "errores de dirección una vez despachado" o "cambios de dirección después del despacho" son para las empresas de despacho reprogramación de despacho, lo que corresponde a un nuevo cobro extra. Este cobro extra no es responsabilidad de la tienda <strong>TOYTEK</strong>. Por esto, probablemente se le solicite <strong>RUT</strong> y confirmar su dirección antes del despacho, evitando malos entendidos. 
              <br />
              Asumimos la responsabilidad y preocupación del cliente al momento de entregar sus datos de despacho o informar cambios vía correo.
            </p>
            <ul>
              <li>Despachos se realizan una vez pagado el producto.</li>
              <li>Los despachos son generalmente por <strong>STARKEN</strong>.</li>
              <li>No se despacha en sobre, sólo cuando es factible.</li>
              <li>
                Se declarará valor real, y el tope de devolución lo define la empresa
                de transportes.
              </li>
              <li>Los despachos son de Lunes a Viernes.</li>
              <li>Los horarios de despacho son generalmente después de las 14:00 horas.</li>
              <li>
                <strong>Los despachos se realizan desde Valparaíso, V Región, Chile.</strong>
              </li>
            </ul>
          </div>
          {/* Devoluciones */}
          <div className="policy-item">
            <h2>Devoluciones</h2>
            <p className="lead">
              Si el cliente se arrepiente de una compra y ya tiene en su poder los productos, los gastos de despacho por motivo de devolución corren por cuenta del cliente. 
              Los reembolsos se realizarán una vez recibido el producto en las mismas condiciones descritas. 
              Si paga a crédito, los plazos de devolución dependen de la disponibilidad del monto total.
            </p>
            <ul>
              <li>
                Si no se ha realizado el despacho, el plazo de devolución dependerá
                de las entidades bancarias en pagos con tarjeta.
              </li>
              <li>
                Las transferencias se devolverán en un plazo de 12 horas una vez
                acreditado el pago.
              </li>
            </ul>
          </div>
        </section>
      </div>);
}

export default Header;