import React from "react";
import Video from '../components/video.js';
import Search from '../components/Searcher.js';
function App() {

return (
  <>
  <Video  nombre="/assets/video/toytek.mp4" 
          caption="portada" 
          replay={true} 
   /><Search valor={window.location.search.substring(1,window.location.search.length)} categoriaNombre={'Busqueda'}/></>
);
}

export default App;