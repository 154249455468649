import React from "react";
import '../css/style.css';
import '../App.css';
import Video from '../components/video.js';
import Categorias from '../components/categorias.js';
import PDetalle from '../components/productoDetalle.js';

function App() {

return (
  <>
  <Video  nombre="/assets/video/toytek.mp4" 
          caption="portada" 
          replay={true} 
    /><PDetalle />
            <div><Categorias /></div></>
);
}

export default App;