import React from "react";
import { Link } from 'react-router-dom';
function rutaNavegacion(props) {
    
  return (<div className="container-fluid">
    <div className="row px-xl-5">
        <div className="col-12">
            <nav className="breadcrumb bg-light mb-30">
                <Link className="breadcrumb-item text-dark2" to="/home">Inicio</Link>
                <Link className="breadcrumb-item text-dark2" to={props.url1}>{props.categoria1}</Link>
                <Link className="breadcrumb-item text-dark2" to={props.url}>{props.categoria}</Link>
                <span className="breadcrumb-item active">{props.descripcion}</span>
            </nav>
        </div>
    </div>
</div>);
}

export default rutaNavegacion;
