import React from 'react';
import Video from '../components/video.js';
import Breadcrumb from '../components/breadcrumb.js';
import Galeria from '../components/galeria_productosCategorias.js';
function ProductosGal() {
  return (
  <>
  <Video  nombre="/assets/video/toytek.mp4" 
          caption="portada" 
          replay={true} 
   /><Breadcrumb categoria={'Categoría de Productos'} descripcion={'Categoría de Productos'}></Breadcrumb>
   <Galeria /></>
);
}

export default ProductosGal;