import React from 'react';
import Video from '../components/video.js';
import FormularioLogin from '../components/FormularioLogin.js';

function LoginForm({ setAuth }) {

    return (
      <>
      <Video  nombre="/assets/video/toytek.mp4" 
              caption="portada" 
              replay={true} 
       />
       <FormularioLogin setAuth={setAuth} /></>
    );
    }
    
export default LoginForm;