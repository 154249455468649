
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../css/style.css';
import CarroNumero from './carroNumero.js'
import { Justify, ArrowDownSquare, Youtube, Instagram, Facebook, Tiktok, CameraReels } from 'react-bootstrap-icons';

function NavCarrito() {

    const [isNavbarVerticalOpen, setIsNavbarVerticalOpen] = useState(false);
    const navbarVerticalRef = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };
  
/*     const closeMenuOnHover = () => {
      setIsMenuOpen(false);
    }; */

    const toggleNavbarVertical = () => {
        setIsNavbarVerticalOpen(!isNavbarVerticalOpen);
    };

    const closeNavbarVertical = () => {
        setIsNavbarVerticalOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                navbarVerticalRef.current &&
                !navbarVerticalRef.current.contains(event.target)
            ) {
    /*             console.log('Cerrando menú'); */
                setIsNavbarVerticalOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return (<div className="container-fluid bg-dark mb-30" style={{ position: 'relative', zIndex: 1 }}>
        <div className="row px-xl-5">
            <div className="col-lg-3 d-none d-lg-block">
            <button className="btn d-flex align-items-center justify-content-between bg-primary w-100" onClick={toggleNavbarVertical} style={{ height: '65px', padding: '0 30px' }} title="Menú Categorías" aria-label="Menú Categorías">
                    <h6 className="text-dark m-0"><Justify size={28}></Justify>Categorías</h6>
                    <ArrowDownSquare size={28} color='white'></ArrowDownSquare>
                </button>
                <nav ref={navbarVerticalRef} onMouseLeave={closeNavbarVertical}
               className={`collapse position-absolute navbar navbar-vertical navbar-light align-items-start p-0 bg-light custom-navbar-vertical ${isNavbarVerticalOpen ? 'show' : ''}`} id="navbar-vertical"
                style={{ width: 'calc(100% - 30px)', zIndex: '999' }}>
                    <div className="navbar-nav w-100">

                        <div className="nav-item dropdown dropright">
                            <Link to="" className="nav-link dropdown-toggle" data-toggle="dropdown" aria-expanded="false" onClick={() => setIsNavbarVerticalOpen(false)}>Juguetes <i className="fa fa-angle-right float-right mt-1"></i></Link>
                            <div className="dropdown-menu position-absolute rounded-0 border-0 m-0">
                                <Link to="/collections/figuras-de-accion" className="text-dark dropdown-item" style={{ backgroundColor: '#0062f3', color: 'white' }}  >Acción</Link>
                                <Link to="/collections/figuras-articuladas" className="text-dark dropdown-item" style={{ backgroundColor: '#0062f3', color: 'white' }}  >Articuladas</Link>
                                <Link to="/collections/autitos" className="text-dark dropdown-item" style={{ backgroundColor: '#0062f3', color: 'white' }}  >Autitos</Link>
                                <Link to="/collections/figuras-monocromaticas" className="text-dark dropdown-item" style={{ backgroundColor: '#0062f3', color: 'white' }}  >Monocromáticas</Link>
                                <Link to="/collections/figuras-plasticas" className="text-dark dropdown-item" style={{ backgroundColor: '#0062f3', color: 'white' }}  >Plásticas</Link>
                                <Link to="/collections/figuras-de-pvc" className="text-dark dropdown-item" style={{ backgroundColor: '#0062f3', color: 'white' }}  >PVC</Link>
                            </div>
                        </div>
                        <Link to="/collections/papeleria" className="text-dark nav-item nav-link" style={{ backgroundColor: '#0062f3', color: 'white' }}>Papelería</Link>
                        <Link to="/collections/peliculas" className="text-dark nav-item nav-link" style={{ backgroundColor: '#0062f3', color: 'white' }}>Películas</Link>
                        <Link to="/collections/poleras" className="text-dark nav-item nav-link" style={{ backgroundColor: '#0062f3', color: 'white' }}>Poleras</Link>
                        <Link to="/collections/promocionales" className="text-dark nav-item nav-link" style={{ backgroundColor: '#0062f3', color: 'white' }}>Promocionales</Link>
                        <Link to="/collections/rompecabezas" className="text-dark nav-item nav-link" style={{ backgroundColor: '#0062f3', color: 'white' }}>Rompecabezas</Link>
                        <Link to="/collections/tazas" className="text-dark nav-item nav-link" style={{ backgroundColor: '#0062f3', color: 'white' }}  >Tazas</Link>
                        <Link to="/collections/videojuegos" className="text-dark nav-item nav-link" style={{ backgroundColor: '#0062f3', color: 'white' }}>Videojuegos</Link>
                        <Link to="/collections/variados" className="text-dark nav-item nav-link" style={{ backgroundColor: '#0062f3', color: 'white' }}>Variados</Link>
                        <Link to="/collections/all" className="text-dark nav-item nav-link" style={{ backgroundColor: '#0062f3', color: 'white' }}>TODOS</Link>
                    </div>
                </nav>
            </div>
            <div className="col-lg-9">
                <nav className="navbar navbar-expand-lg bg-dark navbar-dark py-3 py-lg-0 px-0">
                    <div to="" className="text-decoration-none d-block d-lg-none">
                    <Link to="/home" className="px-2" style={{ paddingBottom: '5px' }}><img id="logo-img" src={require(`../assets/logo/toytek.png`)} title="TOYTEK - Juguetes Retro Juguetes nostálgicos Coleccionables de los 70/80/90" aria-label="TOYTEK - Juguetes Retro Juguetes nostálgicos Coleccionables de los 70/80/90" width="150px" height="29px" className="img-fluid" /></Link>
                    <div className="px-2 ml-n1">&nbsp;&nbsp;🇨🇱&nbsp;&nbsp;<Link to="https://www.youtube.com/@toytek_cl" target="_blank" title="Youtube de TOYTEK" aria-label="Youtube de TOYTEK"><Youtube color='white' size={20}></Youtube></Link>&nbsp;&nbsp;<Link to="https://www.instagram.com/toytek.cl/" target="_blank" title="Instagram de TOYTEK" aria-label="Instagram de TOYTEK"><Instagram color='white' size={16}></Instagram></Link>&nbsp;&nbsp;<Link to="https://www.facebook.com/toytek.cl/" target="_blank" title="Facebook de TOYTEK" aria-label="Facebook de TOYTEK"><Facebook color='white' size={16}></Facebook></Link>&nbsp;&nbsp;<Link to="https://tiktok.com/@toytek.cl" target="_blank" title="Tiktok de TOYTEK" aria-label="Tiktok de TOYTEK"><Tiktok color='white' size={16}></Tiktok></Link>&nbsp;&nbsp;<Link to="/galeria-de-videos/" title="Galería de videos TOYTEK" aria-label="Galería de videos de TOYTEK"><CameraReels color='white' size={16}></CameraReels></Link>&nbsp;&nbsp;</div>
                    </div>
                    <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse" title="Menú de TOYTEK" aria-label="Menú de TOYTEK" onClick={toggleMenu}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`justify-content-between collapse navbar-collapse ${isMenuOpen ? "show" : ""}`} id="navbarCollapse">
                        <div className="navbar-nav mr-auto py-0">
                            <Link to="/home" className="nav-item nav-link">Inicio</Link>
                            <div className="nav-item dropdown" onMouseLeave={closeNavbarVertical}>
                                <Link className="nav-link dropdown-toggle" to="" data-toggle="dropdown" aria-expanded="true" >Juguetes <ArrowDownSquare size={20}></ArrowDownSquare></Link>
                                <div className="dropdown-menu bg-primary rounded-0 border-0 m-0 ">
                                    <Link to="/collections/figuras-de-accion" className="text-dark dropdown-item" >Acción</Link>
                                    <Link to="/collections/figuras-articuladas" className="text-dark dropdown-item" >Articuladas</Link>
                                    <Link to="/collections/autitos" className="text-dark dropdown-item" >Autitos</Link>
                                    <Link to="/collections/figuras-monocromaticas" className="text-dark dropdown-item" >Monocromáticas</Link>
                                    <Link to="/collections/figuras-plasticas" className="text-dark dropdown-item" >Plásticas</Link>
                                    <Link to="/collections/figuras-de-pvc" className="text-dark dropdown-item" >PVC</Link>
                                </div>
                            </div>
                            <div className="nav-item dropdown" onMouseLeave={closeNavbarVertical}>
                                <Link className="nav-link dropdown-toggle" to="" data-toggle="dropdown" aria-expanded="true" >Otras <ArrowDownSquare size={20}></ArrowDownSquare></Link>
                                <div className="dropdown-menu bg-primary rounded-0 border-0 m-0 ">
                                    <Link to="/collections/papeleria" className="text-dark dropdown-item" >Papelería</Link>
                                    <Link to="/collections/peliculas" className="text-dark dropdown-item" >Películas</Link>
                                    <Link to="/collections/poleras" className="text-dark dropdown-item" >Poleras</Link>
                                    <Link to="/collections/promocionales" className="text-dark dropdown-item" >Promocionales</Link>
                                    <Link to="/collections/rompecabezas" className="text-dark dropdown-item" >Rompecabezas</Link>
                                    <Link to="/collections/tazas" className="text-dark dropdown-item" >Tazas</Link>
                                    <Link to="/collections/videojuegos" className="text-dark dropdown-item" >Videojuegos</Link>
                                    <Link to="/collections/variados" className="text-dark dropdown-item" >Variados</Link>
                                    <Link to="/collections/all" className="text-dark dropdown-item" >TODOS</Link>
                                </div>
                            </div>
                            <Link to="/galeria-de-videos/" className="nav-item nav-link" ><CameraReels color='yellow' size={20} /> Videos</Link>
                            <Link to="/account/logout" className="nav-item nav-link" style={{color:'yellow'}} >Salir</Link>
                        </div>
                        <div className="navbar-nav ml-auto py-0 d-none d-lg-block">
                            <CarroNumero />
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
    );
}

export default NavCarrito;