import React from "react";
import Video from '../components/video.js';
import FormularioRegistro from '../components/FormularioRegistro.js';

function App() {

return (
  <>
  <Video  nombre="/assets/video/toytek.mp4" 
          caption="portada" 
          replay={true} 
   />
   <FormularioRegistro /></>
);
}

export default App;