import React from "react";
import Video from '../components/video.js';
/* import Wsp from '../components/wsp.js'; */
import Elementos from '../components/Productos.js';

function App(props) {

    return (
        <>
            <Video nombre="../assets/video/toytek.mp4"
                caption="portada"
                replay={true}
            />
            {(props.categoriaId === '0') && (<Elementos categoriaId={props.categoriaId} categoriaNombre={props.categoriaNombre} vendido="NO" destacado="" novedad=""/>)}
            {(props.categoriaId === '1') && (<Elementos categoriaId={props.categoriaId} categoriaNombre={props.categoriaNombre} vendido="" destacado="" novedad=""/>)}
            {(props.categoriaId === '2') && (<Elementos categoriaId={props.categoriaId} categoriaNombre={props.categoriaNombre} vendido="" destacado="" novedad=""/>)}
            {(props.categoriaId === '3') && (<Elementos categoriaId={props.categoriaId} categoriaNombre={props.categoriaNombre} vendido="" destacado="" novedad=""/>)}
            {(props.categoriaId === '4') && (<Elementos categoriaId={props.categoriaId} categoriaNombre={props.categoriaNombre} vendido="" destacado="" novedad=""/>)}
            {(props.categoriaId === '5') && (<Elementos categoriaId={props.categoriaId} categoriaNombre={props.categoriaNombre} vendido="" destacado="" novedad=""/>)}
            {(props.categoriaId === '6') && (<Elementos categoriaId={props.categoriaId} categoriaNombre={props.categoriaNombre} vendido="" destacado="" novedad=""/>)}
            {(props.categoriaId === '7') && (<Elementos categoriaId={props.categoriaId} categoriaNombre={props.categoriaNombre} vendido="" destacado="" novedad=""/>)}
            {(props.categoriaId === '8') && (<Elementos categoriaId={props.categoriaId} categoriaNombre={props.categoriaNombre} vendido="" destacado="" novedad=""/>)}
            {(props.categoriaId === '9') && (<Elementos categoriaId={props.categoriaId} categoriaNombre={props.categoriaNombre} vendido="" destacado="" novedad=""/>)}
            {(props.categoriaId === '10') && (<Elementos categoriaId={props.categoriaId} categoriaNombre={props.categoriaNombre} vendido="" destacado="" novedad=""/>)}
            {(props.categoriaId === '11') && (<Elementos categoriaId={props.categoriaId} categoriaNombre={props.categoriaNombre} vendido="" destacado="" novedad=""/>)}
            {(props.categoriaId === '12') && (<Elementos categoriaId={props.categoriaId} categoriaNombre={props.categoriaNombre} vendido="" destacado="" novedad=""/>)}
            {(props.categoriaId === '13') && (<Elementos categoriaId={props.categoriaId} categoriaNombre={props.categoriaNombre} vendido="NO" destacado="SI" novedad=""/>)}
            {(props.categoriaId === '14') && (<Elementos categoriaId={props.categoriaId} categoriaNombre={props.categoriaNombre} vendido="" destacado="" novedad="SI"/>)}
            {(props.categoriaId === '15') && (<Elementos categoriaId={props.categoriaId} categoriaNombre={props.categoriaNombre} vendido="NO" destacado="" novedad=""/>)}
            {(props.categoriaId === '16') && (<Elementos categoriaId={props.categoriaId} categoriaNombre={props.categoriaNombre} vendido="NO" destacado="" novedad=""/>)}
           {/*  <Wsp /> */}</>
    );
}

export default App;