import React, { useEffect } from 'react';
import Video from '../components/video.js';
/* import Wsp from '../components/wsp.js'; */
import CryptoJS from "crypto-js";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";
import Swal from 'sweetalert2';

function Me() {
  const navigate = useNavigate();
  const location = useLocation(); // Captura la ubicación actual
  const queryParams = new URLSearchParams(location.search); // Extrae los parámetros de búsqueda
  const encryptedAccount = queryParams.get("account"); // Obtén el valor del parámetro "account"
  const account = encryptedAccount?.replace(/ /g, '+');


  const activarUsuario = async (user) => {
    try {

      const response = await axios.post('https://mp.toytek.cl/scrud/activar-cuenta', {
        user: user
      });
      if (response.status === 200 && response.data) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Cuenta activada",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6"
        }).then((result) => {
          if (result.isConfirmed) {
           /*  form2.current.reset();
            enviarDatos(String(data.username)); */
            navigate('/');
          }
        });

        console.log('Desactivación Emailing del usuario enviada:', response.data);
      }
    } catch (error) {
      console.error('Error al desactivar el Emailing del usuario:', error);
    }
  };

  // Desencriptar
  const decrypt = (cipherText) => {
    const secretKey = process.env.REACT_APP_CRYPTOJ_KEY;
    try {
      // Restaurar formato de Base64
      const restoredCipherText = cipherText.replace(/-/g, '+').replace(/_/g, '/');

      // Desencriptar
      const bytes = CryptoJS.AES.decrypt(restoredCipherText, secretKey);
      return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error('Error al desencriptar:', error);
      return null; // Devuelve null si el descifrado falla
    }
  };

  useEffect(() => {
    if (account) {
      const urlShow = decrypt(decodeURIComponent(account));
      /* console.log('Valor descifrado:', urlShow); */
      activarUsuario(urlShow);
    } else {
      console.log('No se proporcionó el parámetro "account"');
    }
  }, [account]);

  return (
    <>
      <Video nombre="/assets/video/toytek.mp4"
        caption="portada"
        replay={true}
      />
      {/* <Wsp /> */}</>
  );
}

export default Me;