import Video from '../components/video.js';
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";
import Swal from 'sweetalert2'

const OrdenCompraOk = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [productosEnCarrito, setProductosEnCarrito] = useState(JSON.parse(localStorage.getItem("toytek-carrito")));
  const [email, setEmail] = useState(JSON.parse(localStorage.getItem("toytek-formulario")));
  const isFirstRender = useRef(true);
  // Generar la tabla y enviarla al backend
  const tablaHTML = generarTablaCarrito(productosEnCarrito);

  useEffect(() => {


    if (isFirstRender.current) {
      isFirstRender.current = false;
      // Extrae y convierte los parámetros en un objeto
      const queryParams = new URLSearchParams(location.search);

      // Puedes acceder a los valores así:
      const collectionId = queryParams.get('collection_id').replace('null', null) || null;
      const collectionStatus = queryParams.get('collection_status').replace('null', null) || null;
      const paymentId = queryParams.get('payment_id').replace('null', null) || null;
      const estado = queryParams.get('status').replace('null', null) || null;
      const external_reference  = queryParams.get('external_reference').replace('null', null) || null;
      const payment_type  = queryParams.get('payment_type').replace('null', null) || null;
      const merchant_order_id  = queryParams.get('merchant_order_id').replace('null', null) || null;
      const preferenceId = queryParams.get('preference_id').replace('null', null) || null;
      const site_id  = queryParams.get('site_id').replace('null', null) || null;
      const processing_mode  = queryParams.get('processing_mode').replace('null', null) || null;
      const merchant_account_id  = queryParams.get('merchant_account_id').replace('null', null) || null;
      const urlString = location.search || null;

      const valuesVenta = {
        collectionId: collectionId,
        collectionStatus : collectionStatus,
        paymentId : paymentId,
        estado : estado,
        external_reference : external_reference,
        payment_type : payment_type,
        merchant_order_id : merchant_order_id,
        preferenceId: preferenceId,
        site_id : site_id,
        processing_mode : processing_mode,
        merchant_account_id : merchant_account_id,
        urlString : urlString
      };

   
   /*  console.log(tablaHTML); */ // Usar esto para enviarlo como parte del email

     enviarDatos(valuesVenta);
     
    }
    // Aquí puedes realizar cualquier acción que necesites con los datos capturados

  }, [location.search]);



  const enviarDatos = async (valuesVentaF) => {
    try {
      
      const response = await axios.post('https://mp.toytek.cl/scrud/finalizar-venta', {
        dataEnviada : valuesVentaF
      });
    
      if (response.status === 200) {
        if (localStorage.getItem("toytek-carrito")) {
          if(enviarEmail()){
            // Eliminar el carrito del localStorage
            localStorage.removeItem("toytek-formulario");
            localStorage.removeItem("toytek-carrito");
            sessionStorage.removeItem("productos");
            window.dispatchEvent(new Event("storage"));
            console.log("Carrito eliminado del localStorage");
            navigate('/');
          }
        } else {
              console.log("No hay carrito para eliminar");
        }
       
        console.log('Venta enviada:', response.data);
      }
     
    } catch (error) {
      console.error('Error al enviar Venta:', error);
    }
  };

  const enviarEmail = async () => {
    try {
      console.log('Contenido de tablaHTMLE:', tablaHTML);
      if (!tablaHTML) {
        console.error('tablaHTMLE está vacío o no definido.');
        return false;
      }
      if(tablaHTML !== ""){
        const response = await axios.post('https://mp.toytek.cl/email/compra-exitosa',
            { email: email.email, tablaHTML}
        );

        Swal.fire({
            position: "center",
            icon: "success",
            title: "Enviado",
            text: "Gracias por su compra",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6"
        }).then((result) => {
            if (result.isConfirmed) {
             /*    form2.current.reset(); */
            
            }
        });
        console.log('Datos enviados:', response.data);
        return true;
      }
    } catch (error) {
        console.error('Error al enviar datos:', error);
        return false;
    }
  
};


function generarTablaCarrito(carrito) {
  // Validar que el carrito no esté vacío
  if (!carrito || carrito.length === 0) {
    return `
      <table>
        <thead>
          <tr>
            <th style="background-color: #007bff; color: white;">ID</th>
            <th style="background-color: #007bff; color: white;">Descripción</th>
            <th style="background-color: #007bff; color: white;">Cantidad</th>
            <th style="background-color: #007bff; color: white;">Precio</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="4" style="text-align: center;">No hay productos en el carrito.</td>
          </tr>
        </tbody>
      </table>
    `;
  }

  let totalPagado = 0;

  // Crear las filas dinámicamente
  const filas = carrito.map((producto) => {
    const { id, descripcion, cantidad = 1, valor } = producto; // Valores por defecto
    totalPagado += cantidad * valor;

    return `
      <tr>
        <td style="padding: 8px; border: 1px solid #ddd;">${id}</td>
        <td style="padding: 8px; border: 1px solid #ddd;">${descripcion}</td>
        <td style="padding: 8px; border: 1px solid #ddd; text-align: center;">${cantidad}</td>
        <td style="padding: 8px; border: 1px solid #ddd; text-align: right;">$${(valor * cantidad).toFixed(0)}</td>
      </tr>
    `;
  }).join('');

  // Agregar la fila del total
  const totalFila = `
    <tr>
      <td colspan="3" style="text-align: right; font-weight: bold;">Total:</td>
      <td>$${totalPagado.toFixed(0)}</td>
    </tr>
  `;

  // Retornar la tabla completa
  return `
    <table style="width: 100%; border-collapse: collapse;">
      <thead>
        <tr>
          <th style="background-color: #007bff; color: white; padding: 10px;">ID</th>
          <th style="background-color: #007bff; color: white; padding: 10px;">Descripción</th>
          <th style="background-color: #007bff; color: white; padding: 10px;">Cantidad</th>
          <th style="background-color: #007bff; color: white; padding: 10px;">Precio</th>
        </tr>
      </thead>
      <tbody>
        ${filas}
        ${totalFila}
      </tbody>
    </table>
  `;
}

  return (
    <>
      <Video nombre="../assets/video/toytek.mp4"
        caption="portada"
        replay={true}/></>
  );
}

export default OrdenCompraOk;