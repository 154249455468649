import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const MetaTags = ({ title, description, thumbnail, type }) => {
    const location = useLocation();
    const currentUrl = `${window.location.origin}${location.pathname}${location.search}`;

    return (
        <Helmet>
            <meta charset="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=5.0" />
            {/*   <meta name="theme-color" content="#000000" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta name="msapplication-TileImage" content="https://toytek.cl/assets/icon/ms-icon-144x144.png" /> */}
            <title>{title}</title>
            <meta name="description" content={description}></meta>
            <meta property="og:url" content={currentUrl}/>
            <meta property="og:type" content={type}></meta>
            <meta property="og:title" content={title}></meta>
            <meta property="og:description" content={description}></meta>
            <meta property="og:locale" content="es"/>
            <meta property="og:image" content={thumbnail}/>
            <meta property="og:image:alt" content={description}/>
            <meta property="og:logo" content={"https://toytek.cl/assets/logo/toytek.png"} />
            <meta property="og:image:width" content="1200"/>
            <meta property="og:image:height" content="750"/>
            <meta property="og:site_name" content="TOYTEK"/>
       {/*      <meta property="og:image:secure_url" content={thumbnail || "https://toytek.cl/assets/logo/toytek.jpg?&height=750&width=1200"} /> */}
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:site" content="@toytekcl"/>
          {/*   <meta name="twitter:creator" content="@toytekcl" /> */}
            <link rel="shortcut icon" href="https://toytek.cl/assets/icon/favicon.ico" />
            {/* <link rel="canonical" href="https://toytek.cl/home" /> */}
            <link href="https://toytek.cl/lib/animate/animate.min.css" rel="stylesheet" />
            <link rel="apple-touch-icon" sizes="57x57" href="https://toytek.cl/assets/icon/apple-icon-57x57.png" />
            <link rel="apple-touch-icon" sizes="60x60" href="https://toytek.cl/assets/icon/apple-icon-60x60.png" />
            <link rel="apple-touch-icon" sizes="72x72" href="https://toytek.cl/assets/icon/apple-icon-72x72.png" />
            <link rel="apple-touch-icon" sizes="76x76" href="https://toytek.cl/assets/icon/apple-icon-76x76.png" />
            <link rel="apple-touch-icon" sizes="114x114" href="https://toytek.cl/assets/icon/apple-icon-114x114.png" />
            <link rel="apple-touch-icon" sizes="120x120" href="https://toytek.cl/assets/icon/apple-icon-120x120.png" />
            <link rel="apple-touch-icon" sizes="144x144" href="https://toytek.cl/assets/icon/apple-icon-144x144.png" />
            <link rel="apple-touch-icon" sizes="152x152" href="https://toytek.cl/assets/icon/apple-icon-152x152.png" />
            <link rel="apple-touch-icon" sizes="180x180" href="https://toytek.cl/assets/icon/apple-icon-180x180.png" />
            <link rel="icon" type="image/png" sizes="192x192" href="https://toytek.cl/assets/icon/android-icon-192x192.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="https://toytek.cl/assets/icon/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="96x96" href="https://toytek.cl/assets/icon/favicon-96x96.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="https://toytek.cl/assets/icon/favicon-16x16.png" />
            {/* <link rel="manifest" href="https://toytek.cl/manifest.json" /> */}
        </Helmet>
    );
};

export default MetaTags;