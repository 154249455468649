import React, { useState, useEffect } from 'react';
import { PencilFill, EyeFill, FiletypeXls, TrashFill, FiletypePdf, ShareFill } from 'react-bootstrap-icons';
import '../css/dataGrid.css';

const DataGrid = () => {
  const [rows, setRows] = useState([
    { id: 1, col1: 'Dato 1', col2: 'Dato 2', col3: 'Dato 3', col4: 'Dato 4', col5: 'Dato 5', col6: 'Dato 6', col7: 'Dato 7' },
    { id: 2, col1: 'Dato A', col2: 'Dato B', col3: 'Dato C', col4: 'Dato D', col5: 'Dato E', col6: 'Dato F', col7: 'Dato G' },
  ]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [popupVisible, setPopupVisible] = useState(false);

  const handleEdit = (row) => {
    setSelectedRow(row);
    setPopupVisible(true);
  };

  const handleSave = () => {
    // Save changes (in real-world apps, send data to backend)
    setPopupVisible(false);
  };

  useEffect(() => {
    if (popupVisible) {
      document.body.classList.add('popup-open');
    } else {
      document.body.classList.remove('popup-open');
    }
  }, [popupVisible]);

  return (
    <div className="data-grid-container" style={{ position: 'relative', zIndex: 1 }}>
      <table className="data-grid">
        <thead>
          <tr>
            <th><input type="checkbox" /></th>
            <th>Columna 1</th>
            <th>Columna 2</th>
            <th>Columna 3</th>
            {/*<th>Columna 4</th>
            <th>Columna 5</th>
            <th>Columna 6</th> */}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row.id}>
              <td><input type="checkbox" /></td>
              <td>{row.col1}</td>
              <td>{row.col2}</td>
              <td>{row.col3}</td>
              {/*<td>{row.col4}</td>
              <td>{row.col5}</td>
              <td>{row.col6}</td>*/}
              <td style={{ width: '10%' }}>
                <p><button className="btn-ver"><EyeFill size={18}></EyeFill></button><button className="btn-action" onClick={() => handleEdit(row)}><PencilFill size={18}></PencilFill></button></p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {popupVisible && selectedRow && (
        <div
          className="popup-overlay"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999, // Asegúrate de que sea mayor al del menú y footer
          }}
        >
          <div
            className="popup-form"
            style={{
              background: 'white',
              padding: '20px',
              borderRadius: '8px',
              width: '90%',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              position: 'relative',
            }}
          >
            <h3>Editar Datos</h3>
            <div className="form-container">
            <form>
              {Object.keys(selectedRow).map((key) => (
                <div key={key} className="form-group">
                  <label>{key}</label>
                  <input
                    type="text"
                    value={selectedRow[key]}
                    onChange={(e) =>
                      setSelectedRow({ ...selectedRow, [key]: e.target.value })
                    }
                  />
                </div>
              ))}
              
              <div className="form-actions">
                <button type="button" onClick={handleSave}>Guardar</button>
                <button type="button" onClick={() => setPopupVisible(false)}>Cancelar</button> <button className="btn-pdf"><FiletypePdf size={18} color='white'></FiletypePdf></button> <button className="btn-ver"><FiletypeXls size={18}></FiletypeXls></button> <button className="btn-action"><ShareFill size={18}></ShareFill></button> <button className="btn-pdf"><TrashFill size={18}></TrashFill></button>
              </div>
            </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DataGrid;
