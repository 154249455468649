import React from "react";
import '../css/foodhut.css';
import '../css/galeria_recuadros.css';
import Categorias from './categorias.js';
function ProductosCategorias() {
      
      return (
        <Categorias/>
      );
}

export default ProductosCategorias;
