
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { PersonFill, KeyFill } from 'react-bootstrap-icons';
import axios from 'axios';
import '../css/FormularioLogin.css'; // Estilos adicionales
import Swal from 'sweetalert2';

function FormularioLogin({ setAuth }) {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [errorMensaje, setErrorMensaje] = useState('');
    const [loading, setLoading] = useState(false);
    const onSubmit = async (data) => {
        setLoading(true);
        setErrorMensaje('');
        try {
            const response = await axios.post('https://mp.toytek.cl/security/login', {
                username: data.username,
                password: data.password
            },
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true  // Para incluir las cookies en el cliente
                });

            console.log('Respuesta completa:', response);  // Ver la respuesta completa
            console.log('Código de estado HTTP:', response.status);  // Verificar el código de estado

            // Solo si el código de estado es 200 y response.data.success es verdadero
            if (response.status === 200 && response.data && response.data.success) {
                /* setAuthToken(response.data.token); */
                if (typeof setAuth === "function") {
                    setAuth(true);
                } else {
                    console.error("setAuth no es una función");
                }
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // Si el código de estado es 401, despliega un mensaje específico
 /*                console.error("Error 401: No autorizado. Verifica tus credenciales."); */
                setErrorMensaje('Usuario o contraseña incorrectos.');
                Swal.fire({
                    title: 'Error!',
                    text: errorMensaje,
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonColor: "#3085d6"
                })
            } else {
                console.error("Error en la solicitud:", error.message);
                setErrorMensaje('Solicitud no ejecutada.');
            }
        } finally {
            setLoading(false);
        }

    };

    return (
        <div className="container-login" style={{zIndex: '999', position: 'relative', marginTop: '280px', padding: '1.5rem', marginLeft: '0', marginRight: '0'}}>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="logo-container">
                <img src="../assets/logo/toytek.png" title="TOYTEK - Juguetes Retro Juguetes nostálgicos Coleccionables de los 70/80/90" aria-label="TOYTEK - Juguetes Retro Juguetes nostálgicos Coleccionables de los 70/80/90" width="150px" height="29px" className="img-fluid" style={{ marginBottom: '1rem' }}/> {/* Asegúrate de tener el logo en la ruta especificada */}
            </div>

            <div className="d-flex justify-content-between">
                <p style={{ fontSize: '1.4rem', fontWeight: 'bold' }}>Iniciar Sesión</p>
            </div>
            <p>{errorMensaje && <span className="text-danger">{errorMensaje}</span>}</p>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label  style={{ fontSize: '0.8rem' }}>Username (Email)</Form.Label>
                <InputGroup>
                    <InputGroup.Text><PersonFill size={20}/></InputGroup.Text>
                    <Form.Control  style={{ fontSize: '0.8rem' }}
                        type="email"
                        placeholder="Ingresa tu email"
                        {...register("username", {
                            required: "El email es obligatorio",
                            pattern: {
                                value: /^\S+@\S+$/i,
                                message: "Formato de email inválido",
                            },
                        })}
                    />
                </InputGroup>
                {errors.username && <span className="text-danger">{errors.username.message}</span>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label style={{ fontSize: '0.8rem' }}>Contraseña</Form.Label>
                <InputGroup>
                    <InputGroup.Text><KeyFill size={20} /></InputGroup.Text>
                    <Form.Control  style={{ fontSize: '0.8rem' }}
                        type="password"
                        placeholder="Ingresa tu contraseña"
                        {...register("password", {
                            required: "La contraseña es obligatoria",
                            minLength: {
                                value: 6,
                                message: "La contraseña debe tener al menos 6 caracteres",
                            },
                        })}
                    />
                </InputGroup>
                {errors.password && <span className="text-danger">{errors.password.message}</span>}
            </Form.Group>

            <Button variant="primary" type="submit" className="w-100 mb-2" disabled={loading}>
            {loading ? 'Cargando...' : 'Ingresar'}
            </Button>
            <Link to="/account/register" className="btn btn-outline-success w-100 mb-2" >Registrarse</Link>
        </Form>
    </div>);
}

export default FormularioLogin;