 
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import CarroNumero from './carroNumero.js'
import { Search, Youtube, Instagram, Facebook, Tiktok, CameraReels} from 'react-bootstrap-icons';

function App() {

    const [searching, setSearching] = useState("");
    const navigate = useNavigate();

        const handleSearch = (e) => {
            const value = e.target.value;
            setSearching(value);
        };
    
        const performSearch = () => {
            const trimmedSearch = searching.trim();
            navigate('/Search?' + encodeURIComponent(trimmedSearch));
          
        };

    return (<div className="container-fluid" style={{ position: 'relative', top:'0', left:'0', zIndex: '1000', backgroundColor:'#000',paddingBottom: '6px'}}>
    
        <div className="row bg-secondary py-1 px-xl-5" style={{textAlign: 'center'}}>
            <div className="col-lg-6 d-lg-none">
                <CarroNumero />
            </div>
        </div>
        <div className="row bg-secondary py-1 px-xl-5">
            <div className="col-lg-6 d-lg-none">
                <div className="align-items-center h-100 ">
                    <form action="">
                        <div className="input-group">
                            <input id="1s" type="text" className="form-control" placeholder="Buscar productos" onChange={handleSearch} value={searching}/>
                            <div className="input-group-append">
                            <button
                                    type="button"
                                    className="input-group-text text-primary"
                                    onClick={performSearch}
                                >
                                    <Search size={24} />
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div className="row align-items-center bg-light py-3 px-xl-5 d-none d-lg-flex" >
            <div className="col-lg-4">
                <Link to="/home" className="text-decoration-none">
                    <img id="logo-img" src={require(`../assets/logo/toytek.png`)} title="TOYTEK - Juguetes Retro Juguetes nostálgicos Coleccionables de los 70/80/90" aria-label="TOYTEK - Juguetes Retro Juguetes nostálgicos Coleccionables de los 70/80/90" width="150px" height="29px" className="img-fluid" />
                </Link>&nbsp;&nbsp;🇨🇱
                &nbsp;&nbsp;<Link to="https://www.youtube.com/@toytek_cl" target="_blank"><Youtube size={24} title="Youtube TOYTEK" aria-label="Youtube de TOYTEK"></Youtube></Link>&nbsp;&nbsp;<Link to="https://www.instagram.com/toytek.cl/" target="_blank" title="Instagram TOYTEK" aria-label="Instagram de TOYTEK"><Instagram size={20}></Instagram></Link>&nbsp;&nbsp;<Link to="https://www.facebook.com/toytek.cl/" target="_blank" title="Facebook TOYTEK" aria-label="Facebook de TOYTEK"><Facebook size={20}></Facebook></Link>&nbsp;&nbsp;<Link to="https://tiktok.com/@toytek.cl" target="_blank" title="Tiktok TOYTEK" aria-label="Tiktok de TOYTEK"><Tiktok size={16}></Tiktok></Link>&nbsp;&nbsp;<Link to="/galeria-de-videos/" title="Galería de videos TOYTEK" aria-label="Galería de videos de TOYTEK"><CameraReels size={16}></CameraReels></Link>&nbsp;
            </div>
            <div className="col-lg-4 col-6 text-left" >
                <form action="">
                    <div className="input-group">
                        <input id="2s" type="text" className="form-control" placeholder="Buscar productos" onChange={handleSearch} value={searching}/>
                        <div className="input-group-append">
                        <button
                                    type="button"
                                    className="input-group-text text-primary"
                                    onClick={performSearch}
                                >
                                    <Search size={24} />
                                </button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="col-lg-4 col-6 text-right">

            </div>
        </div>
    </div>);
}

export default App;