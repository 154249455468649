import React from "react";
import CardElementos from './CardElementos.js';
import Video from '../components/video.js';
/* import Wsp from '../components/wsp.js'; */
import '../css/galeriaVideos.css';
import Breadcrumb from './breadcrumb.js';

function VideoDataIntroMovies() {

  const imagePathPaso= '/assets/videos-movies/';
/*   const imagePathPaso + arrGijoeSeries5[0][0] +  finalImg = '/images/gi-joe/'; */

const moviesVideosIntro = [{ id: "1", titulo: "PELÍCULAS", temporada: "1-13", url: "peliculas-parte-1"},
  { id: "2", titulo: "PELÍCULAS", temporada: "14-26", url: "peliculas-parte-2"},
  { id: "3", titulo: "PELÍCULAS", temporada: "27-39", url: "peliculas-parte-3"},
  { id: "4", titulo: "CARTOON", temporada: "40-52", url: "peliculas-parte-4"},
  { id: "5", titulo: "PELÍCULAS", temporada: "53-65", url: "peliculas-parte-5"},
  { id: "6", titulo: "PELÍCULAS", temporada: "66-78", url: "peliculas-parte-6"},];
  return (<>
    <Video  nombre="/assets/video/toytek.mp4" 
            caption="portada" 
            replay={true} 
     />
     <div style={{paddingTop: '10px'}}>
     <Breadcrumb categoria={'Galeria de videos'} descripcion={'Movies'} url={'/galeria-de-videos/'}></Breadcrumb>
     <div className="container">
       
          {moviesVideosIntro.map((video) => (
            <div className='row_videos'><div className="col-md-4" key={video.id}>
            <CardElementos 
              id={video.id} 
              nombre={video.titulo} 
              serie={video.temporada} 
              ruta={imagePathPaso + video.id + '.jpg'} 
              url={video.url}
            />
          </div></div>
        ))}
        </div></div></>
    );
}

export default VideoDataIntroMovies;
