import React, { useEffect } from 'react';
import Video from '../components/video.js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Logout() {
  const navigate = useNavigate();

  

useEffect(() => {

  async function deleteAuthToken() {
    try {
        await axios.post('https://mp.toytek.cl/security/logout', {}, { withCredentials: true });
        console.log('Token eliminado');
        navigate('/');
    } catch (error) {
        console.error('Error al eliminar el token:', error);
    }
}
  deleteAuthToken()

}, [navigate])
return (
  <>
  <Video  nombre="/assets/video/toytek.mp4" 
          caption="portada" 
          replay={true} 
   /></>
);
}

export default Logout;