import React from 'react';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { PersonFill, KeyFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import '../css/FormularioRegistro.css';
import axios from "axios";
import Swal from 'sweetalert2';

function FormularioRegistro() {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const form2 = useRef();

    const enviarDatos = async (user) => {
        try {
            const response = await axios.post('https://mp.toytek.cl/email/send-welcome', {
            user
            });
            
            console.log('Datos enviados:', response.data);
            return true;
        } catch (error) {
            console.error('Error al enviar datos:', error);
            return false;
        }
      };

    const onSubmit = async (data) => {

        try {
               const response = await axios.post('https://mp.toytek.cl/scrud/crear-usuario', {
                username: data.username,
                password: data.password
            });
            if (response.status === 201 && response.data) {
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Usuario Creado",
                        html: `Ahora debes <b>revisar tu Email</b> para activar la cuenta`,
                        showConfirmButton: true,
                        confirmButtonColor: "#3085d6"
                    }).then((result) => {
                        if (result.isConfirmed) {
                            form2.current.reset();
                            enviarDatos(String(data.username));
                            navigate('/');
                        }
                    });
                    console.log('Usuario creado:', response.data);
            }
           
          } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'Error al crear el Usuario',
                icon: 'error',
                showConfirmButton: true,
                confirmButtonColor: "#3085d6"
            })
          }

    };
    /*
        const validateRUT = (value) => {
        // Eliminar puntos y guiones para validar correctamente
        const rutClean = value.replace(/\./g, "").replace("-", "");

        // Asegurarse de que el RUT tenga el formato correcto
        if (!/^[0-9]+[0-9Kk]$/.test(rutClean)) {
            return "Formato inválido de RUT o DNI (Ej: 12345678-9)";
        }

        // Separar el número del dígito verificador
        const rutBody = rutClean.slice(0, -1);
        const dv = rutClean.slice(-1).toUpperCase();

        // Calcular el DV
        let sum = 0;
        let multiplier = 2;
        for (let i = rutBody.length - 1; i >= 0; i--) {
            sum += parseInt(rutBody[i]) * multiplier;
            multiplier = multiplier === 7 ? 2 : multiplier + 1;
        }

        const calculatedDV = 11 - (sum % 11);
        const dvExpected = calculatedDV === 11 ? "0" : calculatedDV === 10 ? "K" : String(calculatedDV);

        return dv === dvExpected || "RUT inválido";
    }; 
    */

    // Para verificar que las contraseñas coincidan
    const password = watch("password", "");

    return (
        <div className="container-login" style={{zIndex: '999', position: 'relative', marginTop: '280px', padding: '1.5rem', marginLeft: '0', marginRight: '0'}}>
            <Form onSubmit={handleSubmit(onSubmit)} ref={form2}>
                <div className="logo-container">
                    <img src="../assets/logo/toytek.png" title="TOYTEK - Juguetes Retro Juguetes nostálgicos Coleccionables de los 70/80/90" aria-label="TOYTEK - Juguetes Retro Juguetes nostálgicos Coleccionables de los 70/80/90" width="150px" height="29px" className="img-fluid" style={{marginBottom: '1rem' }} /> {/* Asegúrate de tener el logo en la ruta especificada */}
                </div>
                <div className="d-flex justify-content-between">
                <p style={{ fontSize: '1.4rem', fontWeight: 'bold' }}>Registro</p>
            </div>
                <Form.Group className="mb-3" controlId="username">
                    <Form.Label style={{ fontSize: '0.8rem' }}>Username (Email)</Form.Label>
                    <InputGroup>
                        <InputGroup.Text><PersonFill size={20}/></InputGroup.Text>
                        <Form.Control  style={{ fontSize: '0.8rem' }}
                            type="email"
                             id="username"
                            placeholder="Ingresa tu email"
                            {...register("username", {
                                required: true,
                                pattern: {
                                    value: /^\S+@\S+$/i,
                                    message: "Formato de email inválido",
                                },
                            })}
                        />
                    </InputGroup>
                    {errors.username && <span className="text-danger">{errors.username.message}</span>}
                </Form.Group>

                <Form.Group className="mb-3" controlId="password">
                    <Form.Label style={{ fontSize: '0.8rem' }}>Contraseña</Form.Label>
                    <InputGroup>
                        <InputGroup.Text><KeyFill size={20}/></InputGroup.Text>
                        <Form.Control  style={{ fontSize: '0.8rem' }}
                            type="password"
                              id="password"
                            placeholder="Ingresa tu contraseña"
                            {...register("password", {
                                required: true,
                                minLength: {
                                    value: 6,
                                    message: "La contraseña debe tener al menos 6 caracteres",
                                },
                            })}
                        />
                    </InputGroup>
                    {errors.password && <span className="text-danger">{errors.password.message}</span>}
                </Form.Group>

                <Form.Group className="mb-3" controlId="confirmPassword">
                    <Form.Label style={{ fontSize: '0.8rem' }}>Confirmar Contraseña</Form.Label>
                    <InputGroup>
                    <InputGroup.Text><KeyFill size={20}/></InputGroup.Text>
                    <Form.Control  style={{ fontSize: '0.8rem' }}
                        type="password"
                        placeholder="Confirma tu contraseña"
                        {...register("confirmPassword", {
                            required: true,
                            validate: (value) =>
                                value === password || "Las contraseñas no coinciden",
                        })}
                    />
                    </InputGroup>
                    {errors.confirmPassword && (
                        <span className="text-danger">{errors.confirmPassword.message}</span>
                    )}
                </Form.Group>

                {/* <Form.Group className="mb-3" controlId="formBasicRut">
                    <Form.Label style={{ fontSize: '0.8rem' }}>RUT o DNI (Chile)</Form.Label>
                    <InputGroup>
                        <InputGroup.Text><FaIdCard /></InputGroup.Text>
                        <Form.Control  style={{ fontSize: '0.8rem' }}
                            type="text"
                            placeholder="Ingresa tu RUT o DNI"
                            {...register("rut", {
                                required: "El RUT o DNI es obligatorio",
                                validate: validateRUT,
                            })}
                        />
                    </InputGroup>
                    {errors.rut && <span className="text-danger">{errors.rut.message}</span>}
                </Form.Group> */}

                <Button variant="primary" type="submit" className="w-100 mb-2">
                    Registrarse
                </Button>
            </Form>
        </div>
    );
}

export default FormularioRegistro;