import React, { useState, useEffect } from "react";
/* import CardElementos from './CardElementos.js'; */
import Video from '../components/video.js';
/* import Wsp from '../components/wsp.js'; */
import '../css/galeriaVideos.css';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Breadcrumb from './breadcrumb.js';
import Modal from "react-modal";
import axios from "axios";
import {XCircleFill, CashCoin} from 'react-bootstrap-icons';

function VideoDataMov() {
    const [videos, setVideos] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [videoShow, setVideoShow] = useState([]);
    const url2 = useParams().url2;
    const navigate = useNavigate();
    const imagePathPaso = '/assets/videos-movies/';
    const API_KEY = process.env.REACT_APP_VIDEO_GALLERY; // Reemplaza con tu clave de API
    const moviesVideosIntro = [{ id: "1", titulo: "PELÍCULAS", temporada: "1-13", url: "peliculas-parte-1" },
    { id: "2", titulo: "PELÍCULAS", temporada: "14-26", url: "peliculas-parte-2" },
    { id: "3", titulo: "PELÍCULAS", temporada: "27-39", url: "peliculas-parte-3" },
    { id: "4", titulo: "CARTOON", temporada: "40-52", url: "peliculas-parte-4" },
    { id: "5", titulo: "PELÍCULAS", temporada: "53-65", url: "peliculas-parte-5"},
    { id: "6", titulo: "PELÍCULAS", temporada: "66-78", url: "peliculas-parte-6"},];

  
    useEffect(() => {
        // Filtrar la temporada según la URL

        const moviesMov = moviesVideosIntro.find((t) => t.url === url2);
        const videoIds_movies1 = [
            "DUKGEfhOQHw", "zsrKvAahkHY", "5ZpIzbsEw9s", "w5Kd8zjbc80", "j1m9kWjXT8s", "orcCQIhLkiI", "_TgxMM2cbsI", "StyJRP8-sFk", "GD8GYjjAPb0", "KGRG2_eVkRI", // Video 10
            "q5BgZXuMsI8", "ys9GnKzWww0", "2YR9AErXCuQ", // 13
        ];
        const videoIds_movies2 = [
            "4sBrzQTc4PQ", "ZeGewPM_Ek4", "FOhieo7O8-s", "oCbX94NPgS0", "Wjgq3yEcZnU", "1TBf4slcMBE", "6q3m_wHbdNo", "LqFstMvWhyI", "-KtmxWqAcME", "zw0zylymWJM", // Video 23
            "wgm65Gi1_rQ", "2ryH2nH7wUs", "xGIOnuhx4wU", // 26
        ];

        const videoIds_movies3 = [
            "NJqL46-3iNA", "DIFEPSilJ3k", "G4YOYQowUYw", "rcjIB2wHZ4o", "dzOZSaJPo-c", "XwzLH_HfFek", "iqeatp1VXVA", "DCxHyycjj0Y", "CwlgRcGVb_0", "qcWOl3uw8os", // Video 23
            "DNmw-voiUf8", "43HJwbcBYZI", "YFbQ7u8ImL0", // 39
        ];

        const videoIds_movies4 = [
          "rvi6JvKpRgk", "x39akfG9rMQ", "DTqcOUzDsHc", "7M2FA2rjYg4", "t2Z6cPSfbCc", "MoUDM47EY00", "DWQtmKEoaC8", "1KieCklQHJY", "FMGDgeUKEXk", "D_NouITXdJQ", // Video 23
          "Rn-zoltYPvA", "7bRFQNrZENM", "vLigTKQ1kto", // 39
      ];

      const videoIds_movies5 = [
        "jrcTXOae7wQ", "xQjSPh_zVY0", "nb4FK4t7FpE", "G0VeQOQCVLs", "V8jHepBPfGM", "2a00XtC5ct4", "nl2GvGU5hcA", "9o5guDGHR0o", "k9H2lK7wtJw", "zc4nsuCa6IE", // Video 23
        "hsReDZsoDBs", "zhpQOVzSIrk", "UBZqbQpLx-0", // 39
    ];

    const videoIds_movies6 = [
      "LyHDmS-7G6Y", "Gw-_XzMRJrI", "qFkRRODvqqU", "Ww8nI0cYc_U", "", "", "", "", "", "", // Video 23
      "", "", "", // 39
  ];


    

        if (!moviesMov) {
            // Si no se encuentra, redirigir al inicio
            navigate("/home");
            return;
        }

        // Seleccionar los videos según la temporada
        if (moviesMov.id === "1") {
            setVideoShow(videoIds_movies1);
        } else if (moviesMov.id === "2") {
            setVideoShow(videoIds_movies2);
        } else if (moviesMov.id === "3") {
            setVideoShow(videoIds_movies3);
        } else if (moviesMov.id === "4") {
          setVideoShow(videoIds_movies4);
        } else if (moviesMov.id === "5") {
          setVideoShow(videoIds_movies5);
        } else if (moviesMov.id === "6") {
          setVideoShow(videoIds_movies6);
        } else {
            setVideoShow([]); // Sin videos
        }
    }, [url2, navigate]);


    useEffect(() => {
        // Cargar los datos de los videos de YouTube
        const fetchVideoData = async () => {
          try {
            if (videoShow.length > 0) {
              const response = await axios.get(`https://www.googleapis.com/youtube/v3/videos`, {
                params: {
                  id: videoShow.join(","),
                  part: "snippet,contentDetails",
                  key: API_KEY,
                },
              });
              console.log("API Response:", response.data);
              setVideos(response.data.items || []);
            } else {
              console.log("No video IDs available for fetching.");
              setVideos([]); // Si no hay videos, vaciar la lista
            }
          } catch (error) {
            console.error("Error fetching video data:", error.response?.data || error.message);
            alert(`Error fetching video data: ${error.response?.data?.error?.message || error.message}`);
          }
        };
    
        fetchVideoData();
      }, [videoShow, API_KEY]);

    const openModal = (video) => {
        setSelectedVideo(video);
        setModalIsOpen(true);
      };
    
      const closeModal = () => {
        setModalIsOpen(false);
        setSelectedVideo(null);
      };

    const movies2 = moviesVideosIntro.find((t) => t.url === url2);
    return (<>
        <Video  nombre="/assets/video/toytek.mp4" 
                caption="portada" 
                replay={true} 
         /><div style={{paddingTop: '10px'}}>
      <Breadcrumb categoria={'Movies'} descripcion={movies2.titulo + ' ' + movies2.temporada} url={'/galeria-de-videos/movies/'} ></Breadcrumb>
      <div className="youtube-gallery" style={{ position: 'relative'}} >
        <h2 style={{color: 'white', paddingLeft: '4rem'}}>Galería de Videos</h2>
        <Link to="https://link.mercadopago.cl/toytek" className="text-decoration-none" target="_blank" style={{ paddingLeft: '4rem', color: 'yellow'}}><CashCoin color='yellow' size={16}></CashCoin>&nbsp;&nbsp;Donate</Link>
        <div
          className="video-grid"
          style={{
            position: 'relative',
            display: "flex",
            flexWrap:  "wrap",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
            gap: "20px",
            fontSize: '0.8rem', paddingTop: "20px"
          }}
        >
          {videos.map((video) => (
            <div
              key={video.id}
              onClick={() => openModal(video)}
              style={{ cursor: "pointer", textAlign: "center", paddingTop: '5px' }}
            >
              <img
                src={video.snippet.thumbnails.medium.url}
                alt={video.snippet.title}
                style={{ width: "315px", borderRadius: "8px" }}
              />
              <p style={{backgroundColor:'#fff', color:'#000'}}>
    {video.snippet.title.length > 50
      ? video.snippet.title.substring(0, 47) + "..."
      : video.snippet.title}</p>
            </div>
          ))}
        </div>
  
        {selectedVideo && (
          <Modal
    isOpen={modalIsOpen}
    onRequestClose={closeModal}
    style={{
      content: {
        maxWidth: "800px",
        margin: "auto",
        padding: "20px",
        borderRadius: "15px",
        border: "none",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
        backgroundColor: "#f9f9f9",
      },
      overlay: {
        zIndex: 1000,
        backgroundColor: "rgba(0, 0, 0, 0.6)",
      },
    }}
  >
    <div style={{ position: "relative" }}>
      {/* Close Button */}
      <button
        onClick={closeModal}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "transparent",
          border: "none",
          cursor: "pointer",
          fontSize: "1.5rem",
          color: "#333",
        }}
        aria-label="Close"
      >
        <XCircleFill />
      </button>
  
      {/* Video Content */}
      <h2
        style={{
          fontSize: "1.5rem",
          color: "#333",
          marginBottom: "10px",
          textAlign: "center",
          paddingTop: '60px',
        }}
      >
        {selectedVideo.snippet.title}
      </h2>
      <p
        style={{
          fontSize: "1rem",
          color: "#666",
          marginBottom: "5px",
          textAlign: "center",
        }}
      >
        Canal:{" "}
        <span style={{ fontWeight: "bold", color: "#007BFF" }}>
          {selectedVideo.snippet.channelTitle}
        </span>
      </p>
      <p
        style={{
          fontSize: "1rem",
          color: "#666",
          marginBottom: "20px",
          textAlign: "center",
        }}
      >
        Publicado el:{" "}
        <span style={{ fontWeight: "bold" }}>
          {new Date(selectedVideo.snippet.publishedAt).toLocaleDateString()}
        </span>
      </p>
      <div style={{ textAlign: "center" }}>
        <iframe
          width="100%"
          height="400"
          src={`https://www.youtube.com/embed/${selectedVideo.id}`}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          style={{
            borderRadius: "10px",
            border: "1px solid #ccc",
          }}
        ></iframe>
      </div>
    </div>
  </Modal>
        )}
      </div></div></>
    );
}

export default VideoDataMov;
