import React from "react";
import Video from '../components/video.js';

function OrdenCompraError() {

return (
  <>
  <Video  nombre="/assets/video/toytek.mp4" 
          caption="portada" 
          replay={true} 
   /></>
);
}

export default OrdenCompraError;