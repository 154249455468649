import React from 'react';
import '../css/newCarousel.css';
/* import '../js/jquery/jquery.min.js'; */
import { ArrowLeftShort, ArrowRightShort } from 'react-bootstrap-icons';

/* import '../css/all.min.css'; */
import '../lib/animate/animate.min.css';
import '../lib/owlcarousel/assets/owl.carousel.min.css';
import '../css/style.css';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

function Menu(props) {
    return (<div >
        <div className="container-fluid mb-3">
            <div className="row px-xl-5">
                <div className="col-lg-8">
                    <div id="header-carousel" className="carousel slide carousel-fade mb-30 mb-lg-0" data-ride="carousel">
                    <a title="Cambia Carousel Izquierdo" aria-label="Cambia Carousel Izquierdo"
        className="carousel-control-prev"
        href="#header-carousel"
        role="button"
        data-slide="prev"
        style={{
            left: '0px',
            zIndex: '3',
        }}
    >
        <ArrowLeftShort size={48} color="blue"  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '40px', height: '40px', backgroundColor: 'white', borderRadius: '50%' }}/>
    </a>
    <a title="Cambia Carousel Derecho" aria-label="Cambia Carousel Derecho"
        className="carousel-control-next"
        href="#header-carousel"
        role="button"
        data-slide="next"
        style={{
            right: '0',
            zIndex: '3',
        }}
    >
        <ArrowRightShort size={48} color="blue" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '40px', height: '40px', backgroundColor: 'white', borderRadius: '50%' }}/>
    </a><div className="carousel-inner">
                            <div className="carousel-item position-relative active" style={{ height: '430px' }}>  
                                <link rel="preload" as="image" href="/assets/carousel/carousel-1.jpg" fetchpriority="high"/>
                                <LazyLoadImage className="position-absolute img-fluid" src={`/assets/${props.carouselcarpeta1}/carousel-${props.numero1}.jpg`}  key={props.numero1}  title="TOYTEK - Juguetes Retro Juguetes nostálgicos Coleccionables de los 70/80/90" aria-label="TOYTEK - Juguetes Retro Juguetes nostálgicos Coleccionables de los 70/80/90" width="1000" height="430" fetchpriority="high"/>
                                <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                    <div className="p-3" style={{ maxWidth: '700px' }}>
                                        <h2 className="display-4 text-white mb-3 animate__animated animate__fadeInDown" style={{ textShadow: '-1px 0 black, 0 2px black, 2px 0 black, 0 -1px black', color: 'white' }}>{props.carouseltitulo1}</h2>
                                        <p className="mx-md-5 px-5 animate__animated animate__bounceIn">{props.carouseltexto1}</p>
                                        <Link className="btn btn-outline-primary py-2 px-4 mt-3 animate__animated animate__fadeInUp" to="" alt="toytek.cl" style={{ visibility: props.visiblebtn1 }}>{props.carouselboton1}</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item position-relative" style={{ height: '430px' }}>
                                <LazyLoadImage className="position-absolute w-100 h-100 img-fluid" src={`/assets/${props.carouselcarpeta1}/carousel-${props.numero3}.jpg`} key={props.numero3} alt="APP LITE - Desarrollo y Soporte de Sistemas Informáticos" title="APP LITE - Desarrollo y Soporte de Sistemas Informáticos" aria-label="APP LITE - Desarrollo y Soporte de Sistemas Informáticos" fetchpriority="high" width="1000" height="430"/>
                                <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                    <div className="p-3" style={{ maxWidth: '700px' }}>
                                        <h2 className="display-4 text-white mb-3 animate__animated animate__fadeInDown" style={{ textShadow: '-1px 0 black, 0 2px black, 2px 0 black, 0 -1px black' }}>{props.carouseltitulo3}</h2>
                                        <p className="mx-md-5 px-5 text-yellow animate__animated animate__bounceIn">{props.carouseltexto3}</p>
                                        <Link className="btn btn-outline-primary py-2 px-4 mt-3 animate__animated animate__fadeInUp" to="https://www.applite.cl/" alt="applite.cl" target="_blank" style={{ visibility: props.visiblebtn3 }}>{props.carouselboton3}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="product-offer mb-30 fondoColorDegrades" style={{ height: '200px' }}>
                        <div className="offer-text">
                            <h2 className="text-yellow">{props.textoa1}</h2>
                            <h3 className="text-white mb-3" style={{ textShadow: '-1px 0 black, 0 2px black, 2px 0 black, 0 -1px black', color: 'white' }}>{props.tituloa1}</h3>
                            <Link to="/galeria-de-videos/" alt="Galería de Videos" title="Galería de Videos" aria-label="Galería de Videos" className="btn btn-outline-primary" target="_parent" style={{ visibility: props.visiblebotona1 }}>{props.botona1}</Link>
                        </div>
                    </div>
                    <div className="mb-30 fondoColorDegradesW" title='Despachos desde Valparaíso - Chile 🇨🇱'>
                        <div className="offer-text">
                            <h2 className="text-white" style={{ textShadow: '-1px 0 black, 0 2px black, 2px 0 black, 0 -1px black', color: 'white', textAlign:'center' }}>{props.textob2}</h2>
                            <h3 className="text-white mb-3"></h3>
                            <p><img src={`/assets/logo/starken.png`} alt="starken" title="starken" aria-label="starken" key={props.numerob2} width="123px" height="42px"/>&nbsp;&nbsp;&nbsp;&nbsp;<img src={`/assets/logo/correos-chile.png`} alt="correos-chile" title="correos-chile" aria-label="correos-chile" key={props.numerob2} width="123px" height="42px"/></p>
                            <h3 className="text-white mb-3"  style={{ textShadow: '-1px 0 black, 0 2px black, 2px 0 black, 0 -1px black', color: 'white', textAlign:'center' }}>{props.titulob2}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export default Menu;