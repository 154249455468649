
import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import dataProductos from "../assets/productos.json";
import Breadcrumb from '../components/breadcrumb.js';
import getMetaInfo from '../components/metatags.js';
import ShareButton from '../components/sharepopup.js';
import UrlQR from '../components/qr.js';
import '../css/style.css';
import '../App.css';
import '../css/gallery.css';
import { ArrowLeftShort, ArrowRightShort, Cart4, CaretRightFill, Image, ClipboardDataFill } from 'react-bootstrap-icons';
import Swal from 'sweetalert2'
import axios from 'axios';
/* import { Helmet } from 'react-helmet'; */
import Loader from '../components/preloader.js'

function App() {
    const navigate = useNavigate();
    const [productos, setProductos] = useState(sessionStorage.getItem('productos') || []);
    const productosEnCarrito = JSON.parse(localStorage.getItem("toytek-carrito")) || [];
    const [itemValor, setValor] = useState('');
    const [itemfoto1, setitemfoto1] = useState('');
    const [itemfoto2, setitemfoto2] = useState('');
    const [itemfoto3, setitemfoto3] = useState('');
    const [itemfoto4, setitemfoto4] = useState('');
    const [itemfoto5, setitemfoto5] = useState('');
    const [itemfoto6, setitemfoto6] = useState('');
    const [itemfoto7, setitemfoto7] = useState('');
    const [itemfoto8, setitemfoto8] = useState('');
    const [images, setImages] = useState([itemfoto1, itemfoto2, itemfoto3, itemfoto4, itemfoto5, itemfoto6, itemfoto7, itemfoto8]);
    const url = useParams().url;
    const [loading, setLoading] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0); // Estado para manejar el índice activo
    const handleSelectImage = (index) => {
        setActiveIndex(index); // Actualiza el índice activo
    };
    
    function agregarAlCarritoDetalle(e) {

        const idBoton = e.currentTarget.id;
        const productoAgregado = dataProductos.find((producto) => producto.id === String(idBoton));
        /*    */

        if (productosEnCarrito.some((producto) => producto.id === String(idBoton))) {
            /* const index = productosEnCarrito.findIndex((producto) =>  producto.id ===  Number(idBoton));
            productosEnCarrito[index].cantidad++; */
        }
        else {

            productoAgregado.cantidad = 1;
            productosEnCarrito.push(productoAgregado);
            localStorage.setItem("toytek-carrito", JSON.stringify(productosEnCarrito));
            window.dispatchEvent(new Event("storage"));
            const totalItems = productosEnCarrito.reduce((total, item) => total + item.cantidad, 0);
            const cartSummaryHtml = `
    <div style="text-align: center; font-size: 16px; color: #333;">
      <div style="display: flex; align-items: center; justify-content: center; gap: 10px; margin-bottom: 20px;">
        <span style="font-size: 20px; font-weight: bold;">${totalItems} Producto(s)</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" style="color:#04890c;" fill="currentColor" class="bi bi-cart-check-fill" viewBox="0 0 16 16">
  <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0m7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708"></path>
</svg>
      </div>
    </div>
  `;
            Swal.fire({
                position: "center",
                icon: "success",
                title: "¿ir a pagar?",
                html: cartSummaryHtml,
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Si",
                showCancelButton: true,
                cancelButtonColor: "red",
                cancelButtonText: "No, gracias."
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/cart');
                }
            });

        }

        /* navigate('/cart'); */
    }

    const cambio = (product) => {

        const nf = new Intl.NumberFormat("es-CL");
        if (product) {
            if (product.cantidad > 0)
                setValor(`$ ${nf.format(product.valor)}.-`);
            else
                setValor(`VENDIDO`);
        }
    }
   
    
    useEffect(() => {
        const revisaF = (item) => {
            revisaFotos1(item);
            revisaFotos2(item);
            revisaFotos3(item);
            revisaFotos4(item);
            revisaFotos5(item);
            revisaFotos6(item);
            revisaFotos7(item);
            revisaFotos8(item);
            if(item)
                setImages([itemfoto1, itemfoto2, itemfoto3, itemfoto4, itemfoto5, itemfoto6, itemfoto7, itemfoto8].filter((img) => img && img !== '.JPG'));
        }
    
     
        const revisaFotos1 = (itemF) => {
        /*     let fotoFin = ''; */
            if (itemF.categorias === Number(15) || itemF.categorias === Number(16)) {
                if (itemF.foto1 !== '')
                    setitemfoto1(`${itemF.foto1}`);
            }
            else {
                setitemfoto1(`${itemF.foto1}.JPG`);
            }
            /* setitemfoto1(fotoFin); */
        }
    
        const revisaFotos2 = (itemF) => {
          /*   let fotoFin = ''; */
            if (itemF.categorias === Number(15) || itemF.categorias === Number(16)) {
                if (itemF.foto2 !== '')
                    setitemfoto2(`${itemF.foto2}`);
            }
            else {
                setitemfoto2(`${itemF.foto2}.JPG`);
            }
        /*     setitemfoto2(fotoFin); */
        }
    
        const revisaFotos3 = (itemF) => {
         /*    let fotoFin = ''; */
            if (itemF.categorias === Number(15)) {
                if (itemF.foto3 !== '')
                    setitemfoto3(`${itemF.foto3}`);
            }
            else if (itemF.categorias === Number(16)) {
            }
            else {
                if (itemF.foto3 !== '')
                    setitemfoto3(`${itemF.foto3}.JPG`);
            }
        }
    
        const revisaFotos4 = (itemF) => {
          /*   let fotoFin = '';
            fotoFin = `${itemF.foto4}.JPG`; */
            setitemfoto4(`${itemF.foto4}.JPG`);
        }
    
        const revisaFotos5 = (itemF) => {
          /*   let fotoFin = '';
            fotoFin = `${itemF.foto5}.JPG`; */
            setitemfoto5(`${itemF.foto5}.JPG`);
        }
    
        const revisaFotos6 = (itemF) => {
        /*     let fotoFin = '';
            fotoFin = `${itemF.foto6}.JPG`; */
            setitemfoto6(`${itemF.foto6}.JPG`);
        }
    
        const revisaFotos7 = (itemF) => {
          /*   let fotoFin = '';
            fotoFin = `${itemF.foto7}.JPG`; */
            setitemfoto7(`${itemF.foto7}.JPG`);
        }
    
        const revisaFotos8 = (itemF) => {
           /*  let fotoFin = '';
            fotoFin = `${itemF.foto8}.JPG`; */
            setitemfoto8(`${itemF.foto8}.JPG`);
        }
        // Definir la función asíncrona dentro del useEffect
        const fetchProductos = async () => {
            const slug = url;
            try {

                // Realizar la solicitud GET a la API
                const response = await axios.get(`https://mp.toytek.cl/scrud/obtener-producto-detalle?slug=${slug}`);
                if (response.status === 200 && response.data) {
                    setProductos(response.data); // Actualizar el estado con los datos obtenidos
                    revisaF(response.data);
                    cambio(response.data);
                }
            } catch (error) {
                setLoading(false);
                console.error('Error al obtener las Productos:', error);
                // Manejar datos locales como respaldo
                if (Array.isArray(dataProductos)) {
                    const producto = dataProductos.find((producto) => producto.url === String(slug));
                    if (producto) {
                        setProductos(producto);
                        revisaF(producto);
                        cambio(producto);
                    } else {
                        console.warn('No se encontró un producto coincidente en los datos locales.');
                    }
                } else {
                    console.error('El respaldo de datos locales no está disponible o no es válido.');
                }
            } finally {
                setLoading(false);

            }
        };

        const initialize = async () => {
         /*    await verifyAuth(); */
         /*    if (shouldShowLoading) { */
             /*  setLoading(true);
              setTimeout(() => setLoading(false), 2000); // Simula un tiempo de carga */
              // Simula una carga inicial (puedes personalizarlo)
              const timer = setTimeout(() => {
                setLoading(false);
            }, 3000); // 3 segundos
      
            return () => clearTimeout(timer);
           /*  } else {
              setLoading(false);
            } */
          };
      
        

        fetchProductos(); // Llamar a la función asíncrona
        /* setLoading(true); */
        
        initialize();
    }, [url, itemfoto1, itemfoto2, itemfoto3, itemfoto4, itemfoto5, itemfoto6, itemfoto7, itemfoto8]); // Array de dependencias vacío para ejecutar el efecto solo una vez

    // Función para transformar la URL del thumbnail
    const formatThumbnail = (url) => {
        if (!url) return 'https://toytek.cl/assets/logo/toytek.jpg'; // Fallback por defecto
        if (url.startsWith('../')) return url.replace('../', 'https://toytek.cl/');
        return url;
    };
    const location = useLocation();
    /*    const canonicalUrl = `https://toytek.cl${location.pathname}`; */

    const loaderOverlayStyle = {
        position: "relative",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        backgroundColor: "#f8f9fa", // Fondo semitransparente
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000, // Asegura que el loader esté por encima de todo
    };


    if (loading) {
        // Muestra un spinner o indicador de carga mientras se verifica la autenticación
        return (
            <div style={loaderOverlayStyle}>
                <Loader className="justify-content-center" />
            </div>
        );
    }


   
/*     images = [itemfoto1, itemfoto2, itemfoto3, itemfoto4, itemfoto5, itemfoto6, itemfoto7, itemfoto8]; */
  /*   if(productos.categorias === 15 || productos.categorias === 16)
        images = [`${productos.foto1}`, `${productos.foto2}`, `${productos.foto3}`, `${productos.foto4}`, `${productos.foto5}`, `${productos.foto6}`, `${productos.foto7}`, `${productos.foto8}`].filter((img) => img && img !== '.png');
 */

    const handleNextImage = () => {
        setActiveIndex((current) => (current + 1) % images.length);
    };
    
    const handlePrevImage = () => {
        setActiveIndex((current) => (current - 1 + images.length) % images.length);
    };


    
    return (<div>{productos && (<>
        {/* Meta tags dinámicos */}
        {getMetaInfo({
            title: productos.descripcion || 'Producto no disponible',
            description: productos.descripcion || 'Detalles del producto no disponibles.',
            thumbnail: formatThumbnail(itemfoto1),
            type: 'product',
        })}
        {/*   cambio({productos.cantidad}) */}
        <Breadcrumb categoria={productos.cat_nombre} descripcion={productos.descripcion} url={productos.cat_url}></Breadcrumb>
        <div className="container-fluid pb-5">
            <div className="row px-xl-5">
                <div className="col-lg-5 mb-30">
                    <div id="product-carousel" className="carousel slide" data-ride="carousel">
                        <div className="carousel-inner bg-light">
                            <div className="carousel-item active">
                                <LazyLoadImage
                                    className="w-100 h-100 img-fluid"
                                    src={itemfoto1}
                                    title={`${productos.descripcion}-1`}
                                    aria-label={`${productos.descripcion}-1`}
                                    width="1620px"
                                    height="1080px"

                                />
                            </div>
                            {itemfoto2 && (
                                <div className="carousel-item">
                                    <LazyLoadImage
                                        className="w-100 h-100 img-fluid"
                                        src={itemfoto2}
                                        title={`${productos.descripcion}-2`}
                                        aria-label={`${productos.descripcion}-2`}
                                        width="1620px"
                                        height="1080px"
                                    />
                                </div>
                            )}
                            {itemfoto3 && itemfoto3 !== '.JPG' && (
                                <div className="carousel-item">
                                    <LazyLoadImage 
                                        className="w-100 h-100 img-fluid"
                                        src={itemfoto3}
                                        title={`${productos.descripcion}-3`}
                                        aria-label={`${productos.descripcion}-3`}
                                        width="1620px"
                                        height="1080px"
                                    />
                                </div>
                            )}
                            {itemfoto4 && itemfoto4 !== '.JPG' && (
                                <div className="carousel-item">
                                    <LazyLoadImage 
                                        className="w-100 h-100 img-fluid"
                                        src={itemfoto4}
                                        title={`${productos.descripcion}-4`}
                                        aria-label={`${productos.descripcion}-4`}
                                        width="1620px"
                                        height="1080px"
                                    />
                                </div>
                            )}
                            {itemfoto5 && itemfoto5 !== '.JPG' && (
                                <div className="carousel-item">
                                    <LazyLoadImage
                                        className="w-100 h-100 img-fluid"
                                        src={itemfoto5}
                                        title={`${productos.descripcion}-5`}
                                        aria-label={`${productos.descripcion}-5`}
                                        width="1620px"
                                        height="1080px"
                                    />
                                </div>
                            )}
                            {itemfoto6 && itemfoto6 !== '.JPG' && (
                                <div className="carousel-item">
                                    <LazyLoadImage
                                        className="w-100 h-100 img-fluid"
                                        src={itemfoto6}
                                        title={`${productos.descripcion}-6`}
                                        aria-label={`${productos.descripcion}-6`}
                                        width="1620px"
                                        height="1080px"
                                    />
                                </div>
                            )}
                            {itemfoto7 && itemfoto7 !== '.JPG' && (
                                <div className="carousel-item">
                                    <LazyLoadImage
                                        className="w-100 h-100 img-fluid"
                                        src={itemfoto7}
                                        title={`${productos.descripcion}-7`}
                                        aria-label={`${productos.descripcion}-7`}
                                        width="1620px"
                                        height="1080px"
                                    />
                                </div>
                            )}
                            {itemfoto8 && itemfoto8 !== '.JPG' && (
                                <div className="carousel-item">
                                    <LazyLoadImage
                                        className="w-100 h-100 img-fluid"
                                        src={itemfoto8}
                                        title={`${productos.descripcion}-8`}
                                        aria-label={`${productos.descripcion}-8`}
                                        width="1620px"
                                        height="1080px"
                                    />
                                </div>
                            )}
                        </div>
                        <Link
    className="carousel-control-prev"
    data-target="#product-carousel"
    data-slide="prev"
    title="Cambia Imagen a la Izquierda"
    aria-label="Cambia Imagen a la Izquierda"
    onClick={() => {
        const carouselElement = document.querySelector('#product-carousel');
        if (!carouselElement) return;

        const items = Array.from(carouselElement.querySelectorAll('.carousel-item'));
        if (items.length === 0) return;

        handlePrevImage();
    }}
>
    <ArrowLeftShort
        style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '60px',
            height: '60px',
            backgroundColor: 'white',
            borderRadius: '50%',
        }}
        size={48}
        color="blue"
    />
</Link>

<Link
    className="carousel-control-next"
    data-target="#product-carousel"
    data-slide="next"
    title="Cambia Imagen a la Derecha"
    aria-label="Cambia Imagen a la Derecha"
    onClick={() => {
        const carouselElement = document.querySelector('#product-carousel');
        if (!carouselElement) return;

        const items = Array.from(carouselElement.querySelectorAll('.carousel-item'));
        if (items.length === 0) return;

        handleNextImage();
    }}
>
    <ArrowRightShort
        style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '60px',
            height: '60px',
            backgroundColor: 'white',
            borderRadius: '50%',
        }}
        size={48}
        color="blue"
    />
</Link>


                    </div>
                    {/* Mini galería */}
                    <div className="mini-gallery" style={{ padding: '10px' }}>
                        {images.filter((img) => img && img !== '.JPG').map((img, index) => (
                                <div
                                    key={index}
                                    className={`mini-gallery-item ${index  === activeIndex ? "active" : ""}`}
                                    style={{
                                        border: index === activeIndex ? "2px solid #fff" : "1px solid #ccc",
                                        cursor: "pointer",
                                        padding: "5px",
                                    }}

                                    onClick={() => {
                                        handleSelectImage(index)
                                        const carouselElement = document.querySelector('#product-carousel');
                                        const items = carouselElement.querySelectorAll('.carousel-item');
                                        items.forEach((item, i) => {
                        item.classList.toggle('active', i === index);
                    });
                                    }}
                                >
                                    <img src={img} alt={`Mini ${productos.descripcion}-${index}`} />
                                </div>
                            ))}
                    </div>
                </div>


                <div className="col-lg-7 h-auto mb-30">
                    <div className="h-100 bg-light p-30">
                       
                        <h4 className="font-weight-semi-bold mb-4">{productos.descripcion} - {productos.id}</h4>
                        <div className="d-flex mb-3">
                            <ShareButton url={`https://toytek.cl${location.pathname}`} />
                        </div>
                        <div className="d-flex mb-4">
                            <span style={{ fontSize: '2rem', color: '#000' }}>{itemValor}</span>
                        </div>
                        <div className="d-flex align-items-center mb-4 pt-2" >
                           {/*  <div className="input-group quantity mr-3" style={{ width: '130px' }}>
                                <div className="input-group-btn">
                                    <button className="btn btn-primary btn-minus" title="" aria-label="" disabled>
                                        <ArrowLeftShort size={20} color='white'></ArrowLeftShort>
                                    </button>
                                </div>
                                <label className="form-control bg-secondary border-0 text-center">{productos.cantidad}</label>
                                <div className="input-group-btn">
                                    <button className="btn btn-primary btn-plus" title="" aria-label="" disabled>
                                        <ArrowRightShort size={20} color='white'></ArrowRightShort>
                                    </button>
                                </div>
                            </div> */}
                            <button className="btn btn-outline-primary2 px-3 producto-agregar" style={{ visibility: itemValor === 'VENDIDO' ? 'hidden' : 'visible' }} id={`${productos.id}`} onClick={agregarAlCarritoDetalle} title="Abrir carrito de compras" aria-label="Abrir carrito de compras"><Cart4 size={24}></Cart4></button>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="row px-xl-5">
                <div className="col">
                    <div className="bg-light p-30">
                        <div className="nav nav-tabs mb-4">
                            <a className="nav-item nav-link active" data-toggle="tab" href="#tab-pane-1"><ClipboardDataFill size={16}></ClipboardDataFill> Descripción</a>
                            <a className="nav-item nav-link " data-toggle="tab" href="#tab-pane-2"><Image size={16}></Image> Más Imagenes</a>
                        </div>
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="tab-pane-1">
                                <h4 className="mb-3">Detalle</h4>
                                <p>{productos.descripcion}</p>
                                <p><CaretRightFill size={16}></CaretRightFill> {productos.estado}.</p>
                                <p><CaretRightFill size={16}></CaretRightFill> Todos los artículos son recomendados <b>para mayores de 3 años</b>.</p>
                                <p><CaretRightFill size={16}></CaretRightFill> Los despachos se realizan desde: <b>{productos.sucursal} - Chile</b>.</p>
                                <p><CaretRightFill size={16}></CaretRightFill> Los despachos son por <b>{productos.empresa_envio} {productos.envio}</b>.</p>
                                <p><CaretRightFill size={16}></CaretRightFill> Si tiene dudas consulte.</p>
                                <p className="mb-6"><UrlQR url={`https://toytek.cl${location.pathname}`} ssizze={180}></UrlQR></p>
                            </div>
                            <div className="tab-pane fade" id="tab-pane-2">
                            <h4 className="mb-3">Imagenes</h4>
                            <div className="">
                                <div style={{margin: '10px'}}>
                                    <LazyLoadImage
                                        className="w-100 h-100 img-fluid"
                                        src={itemfoto1}
                                        title={`${productos.descripcion}-1`}
                                        aria-label={`${productos.descripcion}-1`}
                                        width="1620px"
                                        height="1080px"

                                    />
                                </div>
                                {itemfoto2 && (
                                    <div style={{margin: '10px'}}>
                                        <LazyLoadImage
                                            className="w-100 h-100 img-fluid"
                                            src={itemfoto2}
                                            title={`${productos.descripcion}-2`}
                                            aria-label={`${productos.descripcion}-2`}
                                            width="1620px"
                                            height="1080px"
                                        />
                                    </div>
                                )}
                                {itemfoto3 && itemfoto3 !== '.JPG' && (
                                    <div style={{margin: '10px'}}>
                                        <LazyLoadImage
                                            className="w-100 h-100 img-fluid"
                                            src={itemfoto3}
                                            title={`${productos.descripcion}-3`}
                                            aria-label={`${productos.descripcion}-3`}
                                            width="1620px"
                                            height="1080px"
                                        />
                                    </div>
                                )}
                                {itemfoto4 && itemfoto4 !== '.JPG' && (
                                    <div style={{margin: '10px'}}>
                                        <LazyLoadImage 
                                            className="w-100 h-100 img-fluid"
                                            src={itemfoto4}
                                            title={`${productos.descripcion}-4`}
                                            aria-label={`${productos.descripcion}-4`}
                                            width="1620px"
                                            height="1080px"
                                        />
                                    </div>
                                )}
                                {itemfoto5 && itemfoto5 !== '.JPG' && (
                                    <div style={{margin: '10px'}}>
                                        <LazyLoadImage
                                            className="w-100 h-100 img-fluid"
                                            src={itemfoto5}
                                            title={`${productos.descripcion}-5`}
                                            aria-label={`${productos.descripcion}-5`}
                                            width="1620px"
                                            height="1080px"
                                        />
                                    </div>
                                )}
                                {itemfoto6 && itemfoto6 !== '.JPG' && (
                                    <div style={{margin: '10px'}}>
                                        <LazyLoadImage
                                            className="w-100 h-100 img-fluid"
                                            src={itemfoto6}
                                            title={`${productos.descripcion}-6`}
                                            aria-label={`${productos.descripcion}-6`}
                                            width="1620px"
                                            height="1080px"
                                        />
                                    </div>
                                )}
                                {itemfoto7 && itemfoto7 !== '.JPG' && (
                                    <div style={{margin: '10px'}}>
                                        <LazyLoadImage
                                            className="w-100 h-100 img-fluid"
                                            src={itemfoto7}
                                            title={`${productos.descripcion}-7`}
                                            aria-label={`${productos.descripcion}-7`}
                                            width="1620px"
                                            height="1080px"
                                        />
                                    </div>
                                )}
                                {itemfoto8 && itemfoto8 !== '.JPG' && (
                                    <div style={{margin: '10px'}}>
                                        <LazyLoadImage
                                            className="w-100 h-100 img-fluid"
                                            src={itemfoto8}
                                            title={`${productos.descripcion}-8`}
                                            aria-label={`${productos.descripcion}-8`}
                                            width="1620px"
                                            height="1080px"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div></>)}</div>)
}

export default App;
