import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

function Elemento(props) {
 
    return (
    <Card style={{ width: '15rem' }}>
    <Card.Img id={props.id} variant="top" src={props.ruta} />
    <Card.Body>
      <Card.Title style={{ fontSize: 10, color: '#000'}}>{props.nombre} {props.serie}</Card.Title>
      <Link to={props.url}><Button variant="primary" size="sm" style={{ width: '100%', fontSize: '10px'}}>{props.nombre}</Button></Link>
    </Card.Body>
  </Card>
);
 

}
export default Elemento;
