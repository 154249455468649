import React from "react";
import '../App.css';
import Video from '../components/video.js';
import Politicas from '../components/politicas.js';
import Categorias from '../components/categorias.js';

function App() {

return (<>


  <Video  nombre="/assets/video/toytek.mp4" 
          caption="portada" 
          replay={true} 
   />
<div style={{zIndex: '998', position: 'relative' }}><Politicas/><Categorias /></div></>
);
}

export default App;