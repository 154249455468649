import React, { useState, useEffect } from "react";
import CardElementos from './CardElementos.js';
import Video from '../components/video.js';
/* import Wsp from '../components/wsp.js'; */
import '../css/galeriaVideos.css';
import { useParams, useNavigate } from 'react-router-dom';
import BreadcrumbVideo from './breadcrumbVideo.js';

function VideoData() {
  /* const [videos, setVideos] = useState([]); */
  const [videoShow, setVideoShow] = useState([]);
  const url2 = useParams().url2;
  const navigate = useNavigate();
  const imagePathPaso= '/assets/videos-cartoons/';
/*   const imagePathPaso + arrGijoeSeries5[0][0] +  finalImg = '/images/gi-joe/'; */
const temporadasVideosIntro = [{ id: "1", titulo: "HE-MAN Y LOS AMOS DEL UNIVERSO", temporada: "2 Temporadas", url: "he-man-y-los-amos-del-universo-filmation"},
  { id: "2", titulo: "EL SHOW DE LA PANTERA ROSA", temporada: "15 Temporadas", url: "el-show-de-la-pantera-rosa"},
  { id: "3", titulo: "G.I. JOE: UN VERDADERO HÉROE AMERICANO", temporada: "2 Temporadas", url: "gi-joe-un-verdadero-heroe-americano"},
  { id: "4", titulo: "CAPITAN CENTELLA", temporada: "32 Episodios", url: "capitan-centella"},];
  
  useEffect(() => {
    // Filtrar la temporada según la URL
    const cartoons = temporadasVideosIntro.find((t) => t.url === url2);

    if (!cartoons) {
      // Si no se encuentra, redirigir al inicio
      navigate("/home");
      return;
    }

    
    const temporadasVideos1 = [
      { id: "1", titulo: "HE-MAN - LOS AMOS DEL UNIVERSO", temporada: "TEMP1  1 -13", url: "he-man-los-amos-del-universo-filmation-temporada-1-parte-1", urlIntro: "he-man-y-los-amos-del-universo-filmation"},
      { id: "2", titulo: "HE-MAN - LOS AMOS DEL UNIVERSO", temporada: "TEMP1 14-26", url: "he-man-los-amos-del-universo-filmation-temporada-1-parte-2", urlIntro: "he-man-y-los-amos-del-universo-filmation"},
      { id: "3", titulo: "HE-MAN - LOS AMOS DEL UNIVERSO", temporada: "TEMP1 27-39", url: "he-man-los-amos-del-universo-filmation-temporada-1-parte-3", urlIntro: "he-man-y-los-amos-del-universo-filmation"},
      { id: "4", titulo: "HE-MAN - LOS AMOS DEL UNIVERSO", temporada: "TEMP1 40-52", url: "he-man-los-amos-del-universo-filmation-temporada-1-parte-4", urlIntro: "he-man-y-los-amos-del-universo-filmation"},
      { id: "5", titulo: "HE-MAN - LOS AMOS DEL UNIVERSO", temporada: "TEMP1 53-65", url: "he-man-los-amos-del-universo-filmation-temporada-1-parte-5", urlIntro: "he-man-y-los-amos-del-universo-filmation"},
      { id: "6", titulo: "HE-MAN - LOS AMOS DEL UNIVERSO", temporada: "TEMP2 66-78", url: "he-man-los-amos-del-universo-filmation-temporada-2-parte-1", urlIntro: "he-man-y-los-amos-del-universo-filmation"},
      { id: "7", titulo: "HE-MAN - LOS AMOS DEL UNIVERSO", temporada: "TEMP2 79-91", url: "he-man-los-amos-del-universo-filmation-temporada-2-parte-2", urlIntro: "he-man-y-los-amos-del-universo-filmation"},
      { id: "8", titulo: "HE-MAN - LOS AMOS DEL UNIVERSO", temporada: "TEMP2 92-104", url: "he-man-los-amos-del-universo-filmation-temporada-2-parte-3", urlIntro: "he-man-y-los-amos-del-universo-filmation"},
      { id: "9", titulo: "HE-MAN - LOS AMOS DEL UNIVERSO", temporada: "TEMP2 105-117", url: "he-man-los-amos-del-universo-filmation-temporada-2-parte-4", urlIntro: "he-man-y-los-amos-del-universo-filmation"},
      { id: "10", titulo: "HE-MAN - LOS AMOS DEL UNIVERSO", temporada: "TEMP2 118-130", url: "he-man-los-amos-del-universo-filmation-temporada-2-parte-5", urlIntro: "he-man-y-los-amos-del-universo-filmation"},];
    
      const temporadasVideos2 = [
        { id: "11", titulo: "EL SHOW DE LA PANTERA ROSA 1964", temporada: "TEMP1  1- 2", url: "el-show-de-la-pantera-rosa-temporada-1", urlIntro: "el-show-de-la-pantera-rosa"},
        { id: "12", titulo: "EL SHOW DE LA PANTERA ROSA 1965", temporada: "TEMP2  3-14", url: "el-show-de-la-pantera-rosa-temporada-2", urlIntro: "el-show-de-la-pantera-rosa"},
        { id: "13", titulo: "EL SHOW DE LA PANTERA ROSA 1966", temporada: "TEMP3 15-24", url: "el-show-de-la-pantera-rosa-temporada-3", urlIntro: "el-show-de-la-pantera-rosa"},];
    
        const temporadasVideos3 = [
          { id: "14", titulo: "G.I. JOE: UN VERDADERO HÉROE AMERICANO", temporada: "TEMP1   1-13", url: "gi-joe-un-verdadero-heroe-americano-temporada-1", urlIntro: "gi-joe-un-verdadero-heroe-americano"},
          { id: "15", titulo: "G.I. JOE: UN VERDADERO HÉROE AMERICANO", temporada: "TEMP2  56-69", url: "gi-joe-un-verdadero-heroe-americano-temporada-2", urlIntro: "gi-joe-un-verdadero-heroe-americano"},];
    
          const temporadasVideos4 = [
            { id: "31", titulo: "CAPITAN CENTELLA", temporada: "EP  1-13", url: "capitan-centella-capitulos-parte-1", urlIntro: "capitan-centella"},
            { id: "32", titulo: "CAPITAN CENTELLA", temporada: "EP 14-26", url: "capitan-centella-capitulos-parte-2", urlIntro: "capitan-centella"},
            { id: "33", titulo: "CAPITAN CENTELLA", temporada: "EP 27-32", url: "capitan-centella-capitulos-parte-3", urlIntro: "capitan-centella"},];
    // Seleccionar los videos según la temporada
    if (cartoons.id === "1") {
      setVideoShow(temporadasVideos1);
    } else if (cartoons.id === "2") {
      setVideoShow(temporadasVideos2);
    } else if (cartoons.id === "3") {
      setVideoShow(temporadasVideos3);
    } else if (cartoons.id === "4") {
      setVideoShow(temporadasVideos4);
    }else {
      setVideoShow([]); // Sin videos
    }
  }, [url2, navigate]);

  const cartoons2 = temporadasVideosIntro.find((t) => t.url === url2);
  return (<>
    <Video  nombre="/assets/video/toytek.mp4" 
            caption="portada" 
            replay={true} 
     />
     <div style={{paddingTop: '10px'}}>
    {/*  <Breadcrumb categoria={'Galeria de videos'} descripcion={'Cartoons'} url={'/galeria-de-videos/'}></Breadcrumb> */}
     <BreadcrumbVideo categoria={'Cartoons'} categoria1={'Galeria de videos'} descripcion={cartoons2.titulo + ' ' + cartoons2.temporada} url={'/galeria-de-videos/cartoons/'} url1={'/galeria-de-videos/'}></BreadcrumbVideo>
     <div className="container">
       
          {videoShow.map((video) => (
            <div className='row_videos'><div className="col-md-4" key={video.id}>
            <CardElementos 
              id={video.id} 
              nombre={video.titulo} 
              serie={video.temporada} 
              ruta={imagePathPaso + video.id + '.jpg'} 
              url={'/galeria-de-videos/cartoons/view/' + video.url}
            />
          </div></div>
        ))}
        </div></div></>
    );
}

export default VideoData;
