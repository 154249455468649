import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Swal from 'sweetalert2';
import '../css/Unsubscribe.css';

function Unsubscribe(props) {
  const navigate = useNavigate();
  const desactivarEmailing = async (user) => {
    try {

      const response = await axios.post('https://mp.toytek.cl/scrud/desactivar-suscripcion', {
        user: user
      });
      if (response.status === 200 && response.data) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Emailing desactivado",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6"
        }).then((result) => {
          if (result.isConfirmed) {
           /*  form2.current.reset();
            enviarDatos(String(data.username)); */
            navigate('/');
          }
        });

        console.log('Desactivación Emailing del usuario enviada:', response.data);
      }
    } catch (error) {
      console.error('Error al desactivar el Emailing del usuario:', error);
    }
  };

  return (
    <div className="unsubscribe-container" style={{
      zIndex: '998', 
      display: 'flex',
      position: 'relative',
      textAlign: 'center',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
    }}>
      <div className="message-box">
        <img id="logo-img"src={require(`../assets/logo/toytek.png`)} title="TOYTEK - Juguetes Retro Juguetes nostálgicos Coleccionables de los 70/80/90" aria-label="TOYTEK - Juguetes Retro Juguetes nostálgicos Coleccionables de los 70/80/90" width="150px" height="29px" className="img-fluid" style={{paddingBottom: '10px' }} />
        <p>
          Su cuenta de correo electrónico <strong>{props.usuario}</strong> está a un paso para dar de baja, presione el botón para confirmar y dejar de recibir promociones y
          publicidad sobre nuestra tienda <strong>TOYTEK</strong>.
        </p>
        <p>
          Si desea volver a recibir nuestras novedades, por favor inicie sesión en su cuenta y
          seleccione la opción <strong>"Suscribirse a la tienda"</strong>.
        </p><button
          className="unsubscribe-confirm-button"
          onClick={desactivarEmailing(props.usuario)}
          style={{
            marginTop: '1rem',
            padding: '0.8rem 1.5rem',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '1rem',
          }}
        >Confirmación
        </button>
      </div>
    </div>
  );
}

export default Unsubscribe;