import React, { useState } from "react";
import "../css/sharePopup.css";
import { ShareFill, XCircleFill } from 'react-bootstrap-icons';

const ShareButton = ({url}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [copied, setCopied] = useState(false);

  const shareOptions = [
    {
      name: "WhatsApp",
      url: `https://wa.me/?text=${encodeURIComponent(url)}`,
      icon: "https://toytek.cl/assets/logo/ws_icon.png",
    },
    {
      name: "Facebook",
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
      icon: "https://toytek.cl/assets/logo/fb_icon.png",
    },
    {
      name: "Twitter",
      url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`,
      icon: "https://toytek.cl/assets/logo/tw_icon.png",
    },
    {
      name: "LinkedIn",
      url: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}`,
      icon: "https://toytek.cl/assets/logo/lk_icon.png",
    },
    {
        name: "Reddit",
        url: `https://www.reddit.com/submit?url=${encodeURIComponent(url)}&title=${encodeURIComponent("Mira este producto!")}`,
        icon: "https://toytek.cl/assets/logo/red_icon.png",
    },
    {
      name: "Telegram",
      url: `https://t.me/share/url?url=${encodeURIComponent(url)}`,
      icon: "https://toytek.cl/assets/logo/te_icon.png",
    },
    {
      name: "Pinterest",
      url: `https://pinterest.com/pin/create/bookmarklet/?media=https://toytek.cl/assets/logo/toytek.jpg?&height=750&width=1200&url=${encodeURIComponent(url)}`,
      icon: "https://toytek.cl/assets/logo/pt_icon.png",
    },
    {
      name: "Tumblr",
      url: `http://tumblr.com/widgets/share/tool?canonicalUrl=${encodeURIComponent(url)}`,
      icon: "https://toytek.cl/assets/logo/tu_icon.png",
    },
    {
      name: "Instagram",
      url: `https://www.instagram.com/toytek.cl/`,
      icon: "https://toytek.cl/assets/logo/ig_icon.png",
    },
    {
      name: "TikTok",
      url: `https://www.tiktok.com/@toytek.cl`,
      icon: "https://toytek.cl/assets/logo/tt_icon.png",
    },
  ];

  const styles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    },
    shareOptionsCarousel: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      width: '100%',
      margin: '0 auto',
    },
    carouselInner: {
      display: 'flex',
      overflowX: 'auto',
      scrollBehavior: 'smooth',
      gap: '10px',
      padding: '10px 0',
    },
    carouselItem: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      flex: '0 0 auto',
      width: "50px",
      height: "50px",
      textAlign: 'center',
      backgroundColor: "#f1f1f1",
      borderRadius: "50%",
      textDecoration: "none",
      color: "#555",
      cursor: 'pointer',
      overflow: 'hidden',
      fontSize: "1.2rem",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    },
    icon: {
      width: '50px',
      height: '50px',
    },
    copyButton: (copied) => ({
      padding: "10px 20px",
      fontSize: "1rem",
      cursor: "pointer",
      backgroundColor: copied ? "#4CAF50" : "#007bff",
      color: "white",
      border: "none",
      borderRadius: "5px",
    }),
    popup: {
      backgroundColor: "white",
      borderRadius: "10px",
      width: "90%",
      maxWidth: "500px",
      padding: "20px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      textAlign: "center",
      position: "relative",
    },
    closeButton: {
      position: "absolute",
      top: "10px",
      right: "10px",
      background: "none",
      border: "none",
      fontSize: "1.5rem",
      cursor: "pointer",
      color: "#333",
    },
    title: {
      fontSize: "1.5rem",
      marginBottom: "20px",
    },
    urlRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "10px",
    },
    inputUrl: {
      flex: 1,
      padding: "10px",
      fontSize: "0.9rem",
      border: "1px solid #ddd",
      borderRadius: "5px",
      backgroundColor: "#f9f9f9",
      color: "#333",
    },
     shareOptions: {
      display: "flex",
      gap: "15px",
      marginBottom: "20px",
      justifyContent: "center",
      alignItems: "center",
    },
  };
  
  const copyToClipboard = () => {
    navigator.clipboard.writeText(url).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // El mensaje "Copiado" desaparece después de 2 segundos
      });
  };

  return (
    <div className="share-button-container">
      {/* Botón para abrir el popup */}
      <button className="share-button" onClick={() => setShowPopup(true)}>
      <ShareFill/> Compartir
      </button>

      {showPopup && (
  <div className="share-popup-overlay" style={styles.overlay}>
    <div className="share-popup" style={styles.popup}>
      <button className="close-popup" onClick={() => setShowPopup(false)} style={styles.closeButton}>
      <XCircleFill/>
      </button>
      <h3 style={styles.title}>Compartir este producto</h3>
      
      {/* Redes sociales */}
<div className="share-options-carousel" style={styles.shareOptionsCarousel}>
  <div className="carousel-inner" style={styles.carouselInner}>
    {shareOptions.map((option, index) => (
      <a 
        key={index} 
        href={option.url} 
        target="_blank" 
        rel="noopener noreferrer" 
        style={styles.carouselItem}
      >
        {option.icon ? <img src={option.icon} type="image/png" alt={option.name} style={styles.icon}/> : option.name}
      </a>
    ))}
  </div>
</div>

      
      {/* Input de URL con botón para copiar */}
      <div className="share-url" style={styles.urlRow}>
        <input 
          type="text" 
          value={window.location.href} 
          readOnly 
          style={styles.inputUrl}
        />
        <button 
          style={styles.copyButton(copied)} 
          onClick={copyToClipboard}
        >
          {copied ? "¡Copiado!" : "Copiar"}
        </button>
      </div>
    </div>
  </div>
)}
    </div>
  );
};

export default ShareButton;
