import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/style.css';
import {Cart4, CartCheck} from 'react-bootstrap-icons';

function CarroNum() {
    const [numCarrito, setNumCarrito] = useState(0);
    function actualizarNumeroCarrito() {
        const carrito = JSON.parse(localStorage.getItem("toytek-carrito")) || [];
        const numeroProductos = carrito.reduce((acc, producto) => acc + producto.cantidad, 0);
        setNumCarrito(numeroProductos);
    }

    useEffect(() => {
        actualizarNumeroCarrito();

        // Listener para cambios en localStorage (por si el carrito cambia desde otra pestaña o componente)
        const handleStorageChange = () => actualizarNumeroCarrito();
        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);
    return (<Link to="/cart" className="nav-item nav-link">
        {numCarrito > 0 ? <CartCheck size={24} color='#74fe7d'></CartCheck> : <Cart4 size={24} color='white'></Cart4>}&nbsp;
        <span id="numerito" className="badge text-secondary-carrito border border-secondary" style={{ paddingBottom: '2px' }}>{numCarrito}</span>
    </Link>);
}


export default CarroNum;

